import { forwardRef } from 'react';
import { cn } from '~/utils/';
import { useLocalize } from '~/hooks';

const NavLink = forwardRef((props, ref) => {
  const { svg, text, pro, clickHandler, className = '', ...rest } = props;
  const defaultProps = {};
  const localize = useLocalize();
  defaultProps.className = cn(
    'flex cursor-pointer items-center gap-3 rounded-md py-3 px-3 text-sm text-white transition-colors duration-200 hover:bg-gray-500/10',
    className,
  );

  if (clickHandler) {
    defaultProps.onClick = clickHandler;
  }
  return (
    <>
      <a {...defaultProps} ref={ref} {...rest} >
        {svg()}
        {text}
        {pro && text === localize('com_nav_themes') ? (
          <span className="gptpro ml-2 rounded-md bg-green-500 px-1 py-0.5 text-xs text-white">
            PRO
          </span>
        ) : (
          ''
        )}
      </a>
    </>
  );
});
export default NavLink;
