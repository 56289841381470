import { cn } from '~/utils/';

export default function MessagesSquared({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={cn(className ?? '', 'lucide lucide-messages-square')}
      role="img"
      aria-label="Messages Squared icon"
    >
      <path
        role="img"
        aria-label="Messages Squared icon"
        d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z"
      />
      <path
        role="img"
        aria-label="Messages Squared icon"
        d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1"
      />
    </svg>
  );
}
