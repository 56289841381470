import { cn } from '~/utils';

type TSubRowProps = {
  children: React.ReactNode;
  containerClassName?: string;
  innerContainerClassName?: string;
  onClick?: () => void;
};

export default function SubRow({ children, containerClassName = '', innerContainerClassName = '', onClick }: TSubRowProps) {
  return (
    <div className={cn('flex justify-between', containerClassName)} onClick={onClick}>
      <div
        className={cn('flex items-center justify-center gap-1 self-center pt-2 text-xs', innerContainerClassName)}
      >
        {children}
      </div>
    </div>
  );
}
