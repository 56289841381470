import { useEffect, useState } from 'react';
import { addThemeListener } from '~/hooks/ThemeContext';

export default function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    addThemeListener(query, listener);
  }, [matches, query]);

  return matches;
}
