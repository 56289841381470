import React, { useState, useContext } from 'react';
import ThemeIcon from '../svg/ThemeIcon.tsx';
import CrossIcon from '../svg/CrossIcon.tsx';
import './modal.css';
import './style.css';
import Option from './Option.jsx';
import { modes, themes } from './preferences.jsx';

export default function ThemesModal({ isOpen, close }) {
  return (
    <div
      className={`modal-container ${isOpen ? 'active' : ''}`}
      onClick={(e) => e.target.className === 'modal-container active' && close()}
    >
      <div className="modal">
        <div className="modal-header">
          <h1 className="modal-title">Themes</h1>
          <div className="modal-close" onClick={close}>
            <CrossIcon />
          </div>
        </div>
        <div className="modal-body">
          <div className="modal-content">
            <p style={{ marginBottom: '16px' }}>
              <b>MODE</b>
            </p>
            <div className="modes">
              {modes.map((mode, index) => (
                <Option _name="mode" key={index} data={mode} />
              ))}
            </div>

            {/* <hr style={{ marginBottom: '16px' }} /> */}
            <p style={{ marginBottom: '16px' }}>
              <b>COLORS</b>
            </p>

            <div className="themes-options">
              {themes.map((theme, index) => (
                <Option _name="theme" key={index} data={theme} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
