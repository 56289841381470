import React from 'react';

export default function LogOutIcon() {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="h-4 w-4"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Log out icon"
    >
      <path role="img" aria-label="Log out icon" d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
      <polyline role="img" aria-label="Log out icon" points="16 17 21 12 16 7" />
      <line role="img" aria-label="Log out icon" x1="21" y1="12" x2="9" y2="12" />
    </svg>
  );
}
