import { useState } from 'react';
import type { TConversation, TMessage } from 'librechat-data-provider';
import { Clipboard, CheckMark, EditIcon, RegenerateIcon, ContinueIcon } from '~/components/svg';
import { useGenerations } from '~/hooks';
import { cn } from '~/utils';
import { LuDownload } from 'react-icons/lu';
import { IoCopyOutline } from 'react-icons/io5';
import LanguageOption from './LanguageOption';
type THoverButtons = {
  isEditing: boolean;
  enterEdit: (cancel?: boolean) => void;
  copyToClipboard: (setIsCopied: React.Dispatch<React.SetStateAction<boolean>>) => void;
  conversation: TConversation | null;
  isSubmitting: boolean;
  message: TMessage;
  regenerate: () => void;
  handleContinue: (e: React.MouseEvent<HTMLButtonElement>) => void;
  hasImage: boolean;
  copyImage: (setIsCopiedImage: React.Dispatch<React.SetStateAction<boolean>>) => void;
  downloadImage: () => void;
  text: string;
  submitLanguage: (language: string) => void;
};

export default function HoverButtons({
  text,
  isEditing,
  enterEdit,
  copyToClipboard,
  conversation,
  isSubmitting,
  message,
  regenerate,
  handleContinue,
  hasImage,
  copyImage,
  downloadImage,
  submitLanguage,
}: THoverButtons) {
  const isLimited = text.includes('=== LIMITED ACCESS ===');
  const { endpoint } = conversation ?? {};
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedImage, setIsCopiedImage] = useState(false);
  const { hideEditButton, regenerateEnabled, continueSupported } = useGenerations({
    isEditing,
    isSubmitting,
    message,
    endpoint: endpoint ?? '',
  });
  if (!conversation) {
    return null;
  }

  const { isCreatedByUser } = message;

  const onEdit = () => {
    if (isEditing) {
      return enterEdit(true);
    }
    enterEdit();
  };

  if (!text) return null;

  return (
    <div className="hover-buttons visible mt-2 flex h-[25px] items-center gap-3 text-gray-400 md:gap-4 lg:top-0 lg:mt-0 lg:gap-1 lg:pl-2">
      {hasImage && (
        <>
          {/* <button
            className="hover-button rounded-md p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200"
            onClick={() => copyImage(setIsCopiedImage)}
            type="button"
            title="Copy image"
          >
            {isCopiedImage ? <CheckMark /> : <IoCopyOutline />}
          </button> */}

          <button
            className="hover-button rounded-md p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200"
            onClick={downloadImage}
            type="button"
            title="Download image"
          >
            <LuDownload />
          </button>
        </>
      )}
      {!isLimited && isCreatedByUser ? (
        <button
          className={cn(
            'hover-button rounded-md border p-1 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 md:invisible md:group-hover:visible',

            hideEditButton ? 'opacity-0' : '',
            isEditing ? 'active bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-200' : '',
          )}
          onClick={onEdit}
          type="button"
          title="edit"
          disabled={hideEditButton}
        >
          <EditIcon />
        </button>
      ) : null}
      {!isSubmitting && !isLimited && endpoint !== 'dallE' && (
        <button
          className={cn(
            'hover-button rounded-md border p-1 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 md:invisible md:group-hover:visible',
            isCreatedByUser ? '' : 'active',
          )}
          onClick={() => copyToClipboard(setIsCopied)}
          type="button"
          title={isCopied ? 'Copied to clipboard' : 'Copy to clipboard'}
        >
          {isCopied ? <CheckMark /> : <Clipboard />}
        </button>
      )}
      {regenerateEnabled && !isLimited ? (
        <button
          className="hover-button active rounded-md border p-1 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 md:invisible md:group-hover:visible"
          onClick={regenerate}
          type="button"
          title="regenerate"
        >
          <RegenerateIcon className="hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400" />
        </button>
      ) : null}
      {continueSupported && !isLimited ? (
        <button
          className="hover-button active rounded-md border p-1 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 md:invisible md:group-hover:visible "
          onClick={handleContinue}
          type="button"
          title="continue"
        >
          <ContinueIcon className="h-4 w-4 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400" />
        </button>
      ) : null}
      {endpoint !== 'dallE' &&
        !isCreatedByUser &&
        !isLimited &&
        !isSubmitting &&
        conversation.endpoint !== 'google' && <LanguageOption submitLanguage={submitLanguage} />}
    </div>
  );
}
