import EndpointItem from './EndpointItem';

export default function EndpointItems({ endpoints, onSelect, selectedEndpoint, activeEndpoint }) {

  return (
    <>
      {endpoints.map((endpoint) => (
        <EndpointItem
          key={endpoint}
          value={endpoint}
          isSelected={selectedEndpoint === activeEndpoint}
          onSelect={onSelect === activeEndpoint}
          endpoint={endpoint}
          activeEndpoint={activeEndpoint}
        />
      ))}
    </>
  );
}
