const getValue = (key: string) => {
  let keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
  return keyValue ? decodeURIComponent(keyValue[2]) : null;
};

const setValue = (key: string, value: string) => {
  document.cookie = key + '=' + encodeURIComponent(value) + '; path=/';
};


export default { getValue, setValue };
