// useDocumentTitle.js
import { useEffect } from 'react';

function useDocumentTitle(title: string) {
  useEffect(() => {
    document.title = import.meta.env.VITE_APP_TITLE || 'Chatbot Pro | AI-Pro.org';
  }, [title]);
}

export default useDocumentTitle;
