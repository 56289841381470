export default async function fileReader(file: File, readAsText = false): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result) {
        resolve(event.target.result as string);
      } else {
        reject();
      }
    };
    reader.onerror = () => {
      reject();
    };
    if (readAsText) {
      reader.readAsText(file);
    } else {
      reader.readAsDataURL(file);
    }
  });
}
