import { useEffect } from 'react';

const OnMouseEnter = (targetRef: React.RefObject<HTMLElement>, callback: () => void) => {
  useEffect(() => {
    const onMouseEnter = () => {
      callback();
    };

    targetRef.current?.addEventListener('mouseenter', onMouseEnter);

    return () => {
      targetRef.current?.removeEventListener('mouseenter', onMouseEnter);
    };
  }, [targetRef, callback]);

  return null;
};

export default OnMouseEnter;
