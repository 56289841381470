import Conversation from './Conversation';
import { TConversation } from 'librechat-data-provider';

export default function Conversations({
  conversations,
  moveToTop,
}: {
  conversations: TConversation[];
  moveToTop: () => void;
}) {
  const getCookieValue = (key: string) => {
    const keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? decodeURIComponent(keyValue[2]) : null;
  };
  const user_secret_id = import.meta.env.VITE_USER_SECRET_ID
    ? import.meta.env.VITE_USER_SECRET_ID
    : 'chatpro_KJLF4XgSL8wjlGm';

  const cookie_user_secret_id = getCookieValue(user_secret_id);

  if (!cookie_user_secret_id) {
    return null;
  }

  return (
    <>
      {conversations &&
        conversations.length > 0 &&
        conversations.map((convo: TConversation) => {
          return (
            <Conversation key={convo.conversationId} conversation={convo} retainView={moveToTop} />
          );
        })}
    </>
  );
}
