import { usePopup, usePricingPopup, subscriptionType } from '../helper/store';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { cn, cookie } from '~/utils';
import { setCookieValue } from '~/components/helper/global';

const VITE_REGISTER_URL_START =
  import.meta.env.VITE_REGISTER_URL_START ||
  'https://start.ai-pro.org/register?ppg=51&reg_google=on&from_chatapp=on';

const VITE_REGISTER_URL_CHATAPP =
  import.meta.env.VITE_REGISTER_URL_CHATAPP ||
  'https://chatapp.ai-pro.org/register?ppg=51&reg_google=on&from_chatapp=on';

const VITE_PAYMENT_PAGE_URL =
  import.meta.env.VITE_PAYMENT_PAGE_URL || 'https://start.ai-pro.org/pay?ppg=51&from_chatapp=on';

const upgradeUrl = import.meta.env.VITE_UPGRADE_URL || 'https://start.ai-pro.org/upgrade';

const PlanButton = ({ planInterval, planType, tag, intervalChange }) => {
  return (
    <button
      type="button"
      className={cn(
        'relative z-10 w-full px-4 py-3 text-center text-sm uppercase',
        planInterval === planType ? 'cursor-default' : 'cursor-pointer',
      )}
      onClick={planInterval === planType ? undefined : intervalChange}
    >
      {tag && (
        <span className="pointer-events-none absolute -top-4 left-1/2 w-max min-w-[84px] -translate-x-1/2 rounded-full bg-blue-500 px-3 py-0.5 text-white">
          {tag}
        </span>
      )}
      {planType.toUpperCase()}
    </button>
  );
};

const CurrentPlanButton = () => {
  return (
    <button className="cursor-default rounded-lg bg-gray-300 px-6 py-3 font-bold" type="button">
      Your Current Plan
    </button>
  );
};

export default function PricingPopup() {
  const {
    type: sType,
    payment_interval: pInterval,
    isEnterprise,
    setType,
    setPaymentInterval,
    isProMax,
    isPro,
  } = subscriptionType((state) => state);
  const pState = usePricingPopup((state) => state);
  const pStatePop = usePopup((state) => state);
  const [open, setOpen] = useState<boolean>(false);

  async function getPPG() {
    const ppg = import.meta.env.VITE_PPG ? import.meta.env.VITE_PPG : isEnterprise ? '51' : '52';
    const host_api = import.meta.env.VITE_HOST_START;
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_HOST_START}/api/get-pricing`,
        { ppg, from_upgrade: 1 },
        { headers: { 'content-type': 'application/x-www-form-urlencoded' } },
      );
      const output = response.data;
      if (output.success) {
        // console.log('output.data', output.data);
        pState.setPricingData(output.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {
    if (pState.pricingData.length === 0) {
      getPPG();
    }
  });

  const [planInterval, setPlanInterval] = useState('monthly');

  const intervalChange = function () {
    if (planInterval === 'monthly') {
      setPlanInterval('yearly');
    } else {
      setPlanInterval('monthly');
    }
  };

  const getCookieValue = (key: string) => {
    const keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? decodeURIComponent(keyValue[2]) : null;
  };

  const urlParams = new URLSearchParams(window.location.search);
  const mobDisplayPlanParam = urlParams.get('mobDisplayPlan');

  if (mobDisplayPlanParam) {
    setCookieValue('mobDisplayPlan', mobDisplayPlanParam);
  }

  const mobDisplayPlan = getCookieValue('mobDisplayPlan');

  const redirectRegistrationPage = (plan_id: number) => {
    const is_chatapp = getCookieValue('chatapp');

    if (document.referrer.includes('chatapp') || is_chatapp === 'on') {
      window.location.href = VITE_REGISTER_URL_CHATAPP + '&pricing=' + plan_id;
    } else {
      window.location.href = VITE_REGISTER_URL_START + '&pricing=' + plan_id;
    }
  };

  const redirectToPaymentPage = (plan_id: number) => {
    let mixPanelTrack = getCookieValue('mixPanelTrack');
    let mixPanelClickLocation = getCookieValue('mixPanelClickLocation');
    if (typeof pStatePop.mixPanelTrack !== 'undefined' && pStatePop.mixPanelTrack != '') {
      mixPanelTrack = pStatePop.mixPanelTrack;
    }
    if (
      typeof pStatePop.mixPanelClickLocation !== 'undefined' &&
      pStatePop.mixPanelClickLocation != ''
    ) {
      mixPanelClickLocation = pStatePop.mixPanelClickLocation;
    }
    // console.log('chatpro-upgrade-modal pricingpopup', mixPanelTrack, mixPanelClickLocation);
    if (typeof mixpanel !== 'undefined') {
      mixpanel.track('chatpro-pricing-modal', {
        'adid': cookie.getValue('adid'),
        'emailid': cookie.getValue('emailid'),
        'keyword': cookie.getValue('keyword'),
        'pmt': cookie.getValue('pmt') || 'pay2',
        'ppg': cookie.getValue('ppg') || 59,
        'mixPanelTrack': cookie.getValue('mixPanelTrack'),
        'mixPanelClickLocation': cookie.getValue('mixPanelClickLocation'),
      });
    }

    setTimeout(function () {
      if (typeof AIPRO_USER !== 'undefined' && AIPRO_USER.email) {
        window.location.href = VITE_PAYMENT_PAGE_URL + '&pricing=' + plan_id;
      } else {
        redirectRegistrationPage(plan_id);
      }
    }, 1000);
  };

  useEffect(() => {
    
    if (pState.showPricingPopup) {
      const model = cookie.getValue('mixPanelTrack')
        ? cookie.getValue('mixPanelTrack')?.toLocaleLowerCase()
        : '';

      if (typeof AIPRO_USER !== 'undefined' && AIPRO_USER && AIPRO_USER.subscription_type) {
        const subscription_type = AIPRO_USER.subscription_type;
        const payment_interval = AIPRO_USER.payment_interval;

        setType(subscription_type);
        setPaymentInterval(payment_interval);
        
        if (
          (isPro && !isProMax && model === 'gpt-4-pro-max') ||
          (subscription_type.toLocaleLowerCase() === 'basic' && !model?.includes('gpt-3')) ||
          (!isProMax && model?.includes('claude')) || (!isProMax && model?.includes('dalle'))
        ) {
          if (upgradeUrl.includes('?')) {
            window.open(upgradeUrl + (isPro ? '&vjicon=1' : ''), '_blank');
          } else {
            window.open(upgradeUrl + (isPro ? '?vjicon=1' : ''), '_blank');
          }
          pState.setShowPricingPopup(false);
          return;
        }
      }

      if (document.body.style.pointerEvents === 'none') {
        document.body.style.pointerEvents = 'auto';
      }

      setOpen(true);
    } else { 
      setOpen(false);
    }
  }, [pState.showPricingPopup]);

  return (
    <>
      {pState.pricingData.length > 0 && (
        <>
          <div
            id="pricing-modal"
            className={cn(
              'max-h-full transition-opacity duration-200 ease-in md:flex-row',
              open ? 'opacity-100' : 'pointer-events-none opacity-0 delay-200',
            )}
            onClick={(e: any) => {
              if (e.target.id === 'pricing-modal') {
                pState.setShowPricingPopup(false);
              }
            }}
          >
            <div
              id="pricing-content"
              className={cn(
                'relative top-1/2 mx-auto block h-auto max-h-[calc(80vh)] w-full max-w-[90vw] -translate-y-1/2 overflow-auto rounded-lg border bg-white p-5 transition-transform duration-200 ease-linear md:w-fit md:p-10 md:py-9 [&::-webkit-scrollbar-thumb]:bg-gray-100',
                open ? 'scale-100 delay-200' : 'scale-0',
              )}
            >
              <div className="absolute right-5 top-5 md:right-10 md:top-9">
                <IoMdClose
                  className="cursor-pointer text-2xl"
                  onClick={() => {
                    pState.setShowPricingPopup(false);
                  }}
                />
              </div>
              <div className="visibility: block hidden w-full pt-10 md:pt-3">
                <div className="relative mx-auto mb-4 grid w-56 cursor-pointer grid-cols-2 rounded-full bg-gray-100 p-1 text-center">
                  <span
                    className={cn(
                      'pointer-events-none absolute left-1 top-1/2 h-11 w-[calc(50%-4px)] -translate-y-1/2 rounded-full border-2 border-black/60 bg-white transition-transform duration-200',
                      planInterval === 'yearly' ? 'translate-x-[108px]' : '',
                    )}
                  />
                  <PlanButton
                    planInterval={planInterval}
                    planType="monthly"
                    intervalChange={intervalChange}
                    tag={undefined}
                  />
                  <PlanButton
                    planInterval={planInterval}
                    planType="yearly"
                    intervalChange={intervalChange}
                    tag="20% OFF"
                  />
                </div>
              </div>
              <div className="max-auto mt-10 grid w-full max-w-full grid-cols-1 gap-4 md:flex">
                {sType == '' && (
                  <div
                    className={cn(
                      'free mx-auto block w-96 max-w-full overflow-hidden rounded-lg border border-gray-300 px-6 pb-6 pt-14 md:order-first',
                      mobDisplayPlan === 'on' ? 'order-first' : 'order-last',
                    )}
                  >
                    <div className="grid w-full grid-cols-1 gap-4 text-center">
                      <h3 className="text-xl font-bold">FREE</h3>
                      <div className="bold block text-sm font-bold">
                        <span className="inline-block rounded-full bg-gray-300 px-3 py-1">
                          LIMITED USE
                        </span>
                      </div>
                      <p className="text-4xl font-bold text-gray-800">
                        $0
                        <span className="text-sm">
                          {' '}
                          /{planInterval === 'yearly' ? 'year' : 'month'}
                        </span>
                      </p>
                      <CurrentPlanButton />
                      <div className="grid grid-cols-1 gap-2 border-t border-gray-300 pt-4 text-left">
                        <div className="grid grid-cols-[16px_1fr] items-center gap-3">
                          <FaCheck />
                          <p>3 messages daily</p>
                        </div>
                        <div className="grid grid-cols-[16px_1fr] items-center gap-3">
                          <FaCheck />
                          <p>Standard responses</p>
                        </div>
                        <div className="grid grid-cols-[16px_1fr] items-center gap-3">
                          <FaCheck />
                          <p>500 character limit</p>
                        </div>
                        <div className="grid grid-cols-[16px_1fr] items-center gap-3">
                          <FaCheck />
                          <p>Prompt Templates</p>
                        </div>
                        <div className="grid grid-cols-[16px_1fr] items-center gap-3">
                          <FaCheck />
                          <p>Ads might be displayed</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {pState.pricingData.map((pData, key: number) => {
                  if (
                    pData.payment_interval.toLowerCase() == planInterval.toLowerCase() &&
                    (sType.toLowerCase() !== pData.plan_type.toLowerCase() ||
                      (sType.toLowerCase() === pData.plan_type.toLowerCase() &&
                        pInterval.toLowerCase() === pData.payment_interval.toLowerCase()))
                  ) {
                    return (
                      <div
                        className="relative mx-auto block w-96 max-w-full overflow-hidden rounded-lg border border-blue-500 px-6 pb-6 pt-14"
                        key={key}
                      >
                        <div className="grid w-full grid-cols-1 gap-4 text-center">
                          <h3 className="text-xl font-bold">{pData.label}</h3>
                          <div className="bold block text-sm font-bold">
                            <span className="inline-block rounded-full bg-blue-500 px-3 py-1 text-white">
                              BEST OFFER
                            </span>
                          </div>
                          <p className="text-4xl font-bold text-gray-800">
                            ${pData.price}
                            <span className="text-sm lowercase">
                              {' '}
                              /
                              {pData.payment_interval.toLowerCase() === 'yearly' ? 'year' : 'month'}
                            </span>
                          </p>
                          {sType.toLowerCase() === pData.plan_type.toLowerCase() &&
                          pInterval.toLowerCase() === pData.payment_interval.toLowerCase() ? (
                            <CurrentPlanButton />
                          ) : (
                            <button
                              className="flex items-center justify-center rounded-lg bg-blue-500 px-6 py-3 font-bold text-white transition-colors duration-200 ease-in hover:bg-blue-600"
                              onClick={() => {
                                redirectToPaymentPage(pData.plan_id);
                              }}
                            >
                              <span className="w-auto pr-3">Subscribe</span>
                              <svg
                                className="h-auto w-4"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
                                ></path>
                              </svg>
                            </button>
                          )}
                          <div className="grid grid-cols-1 gap-2 border-t border-blue-500/60 pt-4 text-left">
                            <div
                              className="description"
                              dangerouslySetInnerHTML={{ __html: pData.display_txt2 }}
                            ></div>
                          </div>
                          {planInterval === 'yearly' && (
                            <div className="span-highlight absolute left-0 top-0 w-full max-w-full truncate rounded-t-lg bg-blue-100 px-4 py-2 text-sm font-bold text-blue-500">
                              Up to <span className="font-bold underline-offset-1">20% OFF</span> on
                              an annual subscription
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
