export const modes = [
  {
    label: 'Sync to System',
    className: 'sync-to-system',
    value: 'sync-to-system',
    id: 'sync-to-system',
  },
  {
    label: 'Light',
    className: 'light-mode',
    value: 'light',
    id: 'light',
  },
  {
    label: 'Dark',
    className: 'dark-mode',
    value: 'dark',
    id: 'dark',
  },
];
export const themes = [
  {
    label: 'Aubergine',
    className: 'aubergine-theme',
    value: 'aubergine',
    id: 'aubergine',
  },
  {
    label: 'Ochin',
    className: 'ochin-theme',
    value: 'ochin',
    id: 'ochin',
  },
  {
    label: 'Monument',
    className: 'monument-theme',
    value: 'monument',
    id: 'monument',
  },
  {
    label: 'Work hard',
    className: 'work-hard-theme',
    value: 'work-hard',
    id: 'work-hard',
  },
  {
    label: 'Aubergine Classic',
    className: 'aubergine-classic-theme',
    value: 'aubergine-classic',
    id: 'aubergine-classic',
  },
  {
    label: 'Eggplant',
    className: 'eggplant-theme',
    value: 'eggplant',
    id: 'eggplant',
  },
  {
    label: 'Hoth',
    className: 'hoth-theme',
    value: 'hoth',
    id: 'hoth',
  },
  {
    label: 'Mondrian',
    className: 'mondrian-theme',
    value: 'mondrian',
    id: 'mondrian',
  },
  {
    label: 'Gray',
    className: 'default-theme',
    value: 'default',
    id: 'default',
  },
  {
    label: 'Ultraviolet',
    className: 'ultraviolet-theme',
    value: 'ultraviolet',
    id: 'ultraviolet',
  },
  {
    label: 'Mood Indigo',
    className: 'mood-indigo-theme',
    value: 'mood-indigo',
    id: 'mood-indigo',
  },
  {
    label: 'CMYK',
    className: 'cmyk-theme',
    value: 'cmyk',
    id: 'cmyk',
  },
  {
    label: 'Nocturne (default)',
    className: 'nocturne-theme',
    value: 'nocturne',
    id: 'nocturne',
  },
  {
    label: 'Haberdashery',
    className: 'haberdashery-theme',
    value: 'haberdashery',
    id: 'haberdashery',
  },
  {
    label: 'Expensive',
    className: 'expensive-theme',
    value: 'expensive',
    id: 'expensive',
  },
  {
    label: 'Terminal',
    className: 'terminal-theme',
    value: 'terminal',
    id: 'terminal',
  },
  {
    label: 'Sweet Treat',
    className: 'sweet-treat-theme',
    value: 'sweet-treat',
    id: 'sweet-treat',
  },
  {
    label: 'Banana',
    className: 'banana-theme',
    value: 'banana',
    id: 'banana',
  },
  {
    label: 'Choco Mint',
    className: 'choco-mint-theme',
    value: 'choco-mint',
    id: 'choco-mint',
  },
  {
    label: 'Discotheque',
    className: 'discotheque-theme',
    value: 'discotheque',
    id: 'discotheque',
  },
  {
    label: 'Tritanopia',
    className: 'tritanopia-theme',
    value: 'tritanopia',
    id: 'tritanopia',
  },
  // {
  //   label: 'Protaanopia Deuteranopia',
  //   className: 'protaanopia-deuteranopia'
  // }
];
export default { modes, themes };
