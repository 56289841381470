// Frasi in inglese

export default {
  com_ui_examples: 'Esempi',
  com_ui_new_chat: 'Nuova chat',
  com_ui_example_quantum_computing: 'Spiega il calcolo quantistico in termini semplici',
  com_ui_example_10_year_old_b_day: 'Hai idee creative per il compleanno di un bambino di 10 anni?',
  com_ui_example_http_in_js: 'Come faccio una richiesta HTTP in Javascript?',
  com_ui_capabilities: 'Capacità',
  com_ui_capability_remember: 'Ricorda ciò che l\'utente ha detto in precedenza nella conversazione',
  com_ui_capability_correction: 'Consente all\'utente di fornire correzioni aggiuntive',
  com_ui_capability_decline_requests: 'Addestrato a rifiutare richieste inappropriate',
  com_ui_limitations: 'Limitazioni',
  com_ui_limitation_incorrect_info: 'Può occasionalmente generare informazioni errate',
  com_ui_limitation_harmful_biased:
    'Può occasionalmente produrre istruzioni dannose o contenuti tendenziosi',
  com_ui_limitation_limited_2021: 'Conoscenza limitata del mondo e degli eventi dopo il 2021',
  com_ui_input: 'Input',
  com_ui_close: 'Chiudi',
  com_ui_model: 'Modello',
  com_ui_select_model: 'Seleziona un modello',
  com_ui_use_prompt: 'Usa il prompt',
  com_ui_prev: 'Precedente',
  com_ui_next: 'Successivo',
  com_ui_prompt_templates: 'Modelli di prompt',
  com_ui_hide_prompt_templates: 'Nascondi i modelli di prompt',
  com_ui_showing: 'Mostrando',
  com_ui_of: 'di',
  com_ui_entries: 'Voci',
  com_ui_pay_per_call:
    'Tutte le conversazioni con l\'IA in un unico posto. Paga per chiamata e non al mese',
  com_auth_error_login:
    'Impossibile effettuare l\'accesso con le informazioni fornite. Controlla le tue credenziali e riprova.',
  com_auth_no_account: 'Non hai un account?',
  com_auth_sign_up: 'Registrati',
  com_auth_sign_in: 'Accedi',
  com_auth_google_login: 'Accedi con Google',
  com_auth_facebook_login: 'Accedi con Facebook',
  com_auth_github_login: 'Accedi con Github',
  com_auth_discord_login: 'Accedi con Discord',
  com_auth_email: 'Email',
  com_auth_email_required: 'L\'email è obbligatoria',
  com_auth_email_min_length: 'L\'email deve contenere almeno 6 caratteri',
  com_auth_email_max_length: 'L\'email non deve superare i 120 caratteri',
  com_auth_email_pattern: 'Devi inserire un indirizzo email valido',
  com_auth_email_address: 'Indirizzo email',
  com_auth_password: 'Password',
  com_auth_password_required: 'La password è obbligatoria',
  com_auth_password_min_length: 'La password deve contenere almeno 8 caratteri',
  com_auth_password_max_length: 'La password deve contenere meno di 128 caratteri',
  com_auth_password_forgot: 'Password dimenticata?',
  com_auth_password_confirm: 'Conferma password',
  com_auth_password_not_match: 'Le password non corrispondono',
  com_auth_continue: 'Continua',
  com_auth_create_account: 'Crea il tuo account',
  com_auth_error_create:
    'Si è verificato un errore durante il tentativo di registrazione dell\'account. Riprova.',
  com_auth_full_name: 'Nome completo',
  com_auth_name_required: 'Il nome è obbligatorio',
  com_auth_name_min_length: 'Il nome deve contenere almeno 3 caratteri',
  com_auth_name_max_length: 'Il nome non deve superare gli 80 caratteri',
  com_auth_username: 'Nome utente',
  com_auth_username_required: 'Il nome utente è obbligatorio',
  com_auth_username_min_length: 'Il nome utente deve contenere almeno 3 caratteri',
  com_auth_username_max_length: 'Il nome utente non deve superare i 20 caratteri',
  com_auth_already_have_account: 'Hai già un account?',
  com_auth_login: 'Accedi',
  com_auth_reset_password: 'Reimposta la password',
  com_auth_click: 'Clicca',
  com_auth_here: 'QUI',
  com_auth_to_reset_your_password: 'per reimpostare la tua password.',
  com_auth_reset_password_link_sent: 'Email inviata',
  com_auth_reset_password_email_sent:
    'È stata inviata un\'email con ulteriori istruzioni per reimpostare la password.',
  com_auth_error_reset_password:
    'Si è verificato un problema durante la reimpostazione della password. Non è stato trovato alcun utente con l\'indirizzo email fornito. Riprova.',
  com_auth_reset_password_success: 'Reimpostazione della password riuscita',
  com_auth_login_with_new_password: 'Ora puoi accedere con la tua nuova password.',
  com_auth_error_invalid_reset_token: 'Questo token di reimpostazione password non è più valido.',
  com_auth_click_here: 'Clicca qui',
  com_auth_to_try_again: 'per riprovare.',
  com_auth_submit_registration: 'Invia registrazione',
  com_auth_welcome_back: 'Bentornato',
  com_endpoint_bing_enable_sydney: 'Abilita Sydney',
  com_endpoint_bing_to_enable_sydney: 'Per abilitare Sydney',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing può utilizzare fino a 7.000 token per \'contesto\', a cui può fare riferimento per la conversazione. Il limite specifico non è noto, ma potrebbe verificarsi un errore superando i 7.000 token',
  com_endpoint_bing_system_message_placeholder:
    'ATTENZIONE: l\'uso improprio di questa funzionalità può comportare il DIVIETO di utilizzo di Bing! Fai clic su \'Messaggio di sistema\' per le istruzioni complete e il messaggio predefinito, se omesso, che è il preset \'Sydney\' considerato sicuro.',
  com_endpoint_system_message: 'Messaggio di sistema',
  com_endpoint_default_blank: 'predefinito: vuoto',
  com_endpoint_default_false: 'predefinito: falso',
  com_endpoint_default_creative: 'predefinito: creativo',
  com_endpoint_default_empty: 'predefinito: vuoto',
  com_endpoint_default_with_num: 'predefinito: {0}',
  com_endpoint_context: 'Contesto',

  com_endpoint_tone_style: 'Stile tonale',
  com_endpoint_token_count: 'Conteggio token',
  com_endpoint_output: 'Output',
  com_endpoint_google_temp:
    'Valori più alti = più casualità, valori più bassi = più focalizzati e deterministici. Consigliamo di modificare questo parametro o il parametro Top P, ma non entrambi.',
  com_endpoint_google_topp:
    'Top P modifica il modo in cui il modello seleziona i token in uscita. I token vengono selezionati in base alla probabilità più alta a più bassa fino a quando la somma delle loro probabilità raggiunge il valore top-p.',
  com_endpoint_google_topk:
    'Top K modifica il modo in cui il modello seleziona i token in uscita. Un top-k di 1 significa che il token selezionato è il più probabile tra tutti i token nel vocabolario del modello (detto anche decoding greedy), mentre un top-k di 3 significa che il token successivo viene selezionato tra i 3 token più probabili (usando la temperatura).',
  com_endpoint_google_maxoutputtokens:
    'Numero massimo di token che possono essere generati nella risposta. Specificare un valore più basso per risposte più brevi e un valore più alto per risposte più lunghe.',
  com_endpoint_google_custom_name_placeholder: 'Imposta un nome personalizzato per PaLM2',
  com_endpoint_google_prompt_prefix_placeholder:
    'Imposta istruzioni o contesto personalizzati. Ignorato se vuoto.',
  com_endpoint_custom_name: 'Nome personalizzato',
  com_endpoint_prompt_prefix: 'Prefisso del prompt',
  com_endpoint_temperature: 'Temperatura',
  com_endpoint_default: 'predefinito',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Token di output massimi',
  com_endpoint_openai_temp:
    'Valori più alti = più casualità, valori più bassi = più focalizzati e deterministici. Consigliamo di modificare questo parametro o il parametro Top P, ma non entrambi.',
  com_endpoint_openai_max:
    'Il numero massimo di token da generare. La lunghezza totale dei token di input e dei token generati è limitata dalla lunghezza del contesto del modello.',
  com_endpoint_openai_topp:
    'Un\'alternativa alla generazione casuale con temperatura, chiamata campionamento di nucleo, in cui il modello considera i risultati dei token con una massa di probabilità top-p. Quindi 0,1 significa che vengono considerati solo i token che costituiscono il 10% superiore della probabilità. Consigliamo di modificare questo parametro o la temperatura, ma non entrambi.',
  com_endpoint_openai_freq:
    'Numero compreso tra -2,0 e 2,0. I valori positivi penalizzano i nuovi token in base alla loro frequenza nel testo finora, diminuendo la probabilità che il modello ripeta la stessa frase identica.',
  com_endpoint_openai_pres:
    'Numero compreso tra -2,0 e 2,0. I valori positivi penalizzano i nuovi token in base alla loro presenza nel testo finora, aumentando la probabilità che il modello parli di nuovi argomenti.',
  com_endpoint_openai_custom_name_placeholder: 'Imposta un nome personalizzato per ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Imposta istruzioni personalizzate da includere nel messaggio di sistema. Predefinito: nessuno',
  com_endpoint_anthropic_temp:
    'Varia da 0 a 1. Utilizzare una temperatura più vicina a 0 per compiti analitici/multipla scelta e più vicina a 1 per compiti creativi e generativi. Consigliamo di modificare questo parametro o il parametro Top P, ma non entrambi.',
  com_endpoint_anthropic_topp:
    'Top P modifica il modo in cui il modello seleziona i token in uscita. I token vengono selezionati in base alla probabilità più alta a più bassa fino a quando la somma delle loro probabilità raggiunge il valore top-p.',
  com_endpoint_anthropic_topk:
    'Top K modifica il modo in cui il modello seleziona i token in uscita. Un top-k di 1 significa che il token selezionato è il più probabile tra tutti i token nel vocabolario del modello (detto anche decoding greedy), mentre un top-k di 3 significa che il token successivo viene selezionato tra i 3 token più probabili (usando la temperatura).',
  com_endpoint_anthropic_maxoutputtokens:
    'Numero massimo di token che possono essere generati nella risposta. Specificare un valore più basso per risposte più brevi e un valore più alto per risposte più lunghe.',
  com_endpoint_frequency_penalty: 'Penalità di frequenza',
  com_endpoint_presence_penalty: 'Penalità di presenza',
  com_endpoint_plug_use_functions: 'Usa le funzioni',
  com_endpoint_plug_skip_completion: 'Salta il completamento',
  com_endpoint_disabled_with_tools: 'disabilitato con strumenti',
  com_endpoint_disabled_with_tools_placeholder: 'Disabilitato con strumenti selezionati',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Imposta istruzioni personalizzate da includere nel messaggio di sistema. Predefinito: nessuno',
  com_endpoint_set_custom_name:
    'Imposta un nome personalizzato, nel caso in cui tu possa trovare questo preset',
  com_endpoint_preset_name: 'Nome del preset',
  com_endpoint_new_topic: 'Nuovo argomento',
  com_endpoint: 'Endpoint',
  com_endpoint_hide: 'Nascondi',
  com_endpoint_show: 'Mostra',
  com_endpoint_examples: 'Esempi',
  com_endpoint_completion: 'Completamento',
  com_endpoint_agent: 'Agente',
  com_endpoint_show_what_settings: 'Mostra le impostazioni di {0}',
  com_endpoint_save: 'Salva',
  com_endpoint_export: 'Esporta',
  com_endpoint_save_as_preset: 'Salva come preset',
  com_endpoint_not_implemented: 'Non implementato',
  com_endpoint_edit_preset: 'Modifica preset',
  com_endpoint_no_presets: 'Nessun preset ancora',
  com_endpoint_not_available: 'Nessun endpoint disponibile',
  com_endpoint_clear_all: 'Cancella tutto',
  com_endpoint_view_options: 'Visualizza opzioni',
  com_endpoint_save_convo_as_preset: 'Salva la conversazione come preset',
  com_endpoint_my_preset: 'Mio preset',
  com_endpoint_agent_model: 'Modello Agente (Consigliato: GPT-3.5)',
  com_endpoint_completion_model: 'Modello Completamento (Consigliato: GPT-4)',
  com_endpoint_func_hover: 'Abilita l\'uso dei plugin come funzioni di OpenAI',
  com_endpoint_skip_hover:
    'Abilita la possibilità di saltare la fase di completamento, che rivede la risposta finale e le fasi generate',
  com_endpoint_config_token: 'Configura Token',
  com_nav_export_filename: 'Nome file',
  com_nav_export_filename_placeholder: 'Imposta il nome del file',
  com_nav_export_type: 'Tipo',
  com_nav_export_include_endpoint_options: 'Includi le opzioni dell\'endpoint',
  com_nav_enabled: 'Abilitato',
  com_nav_not_supported: 'Non supportato',
  com_nav_export_all_message_branches: 'Esporta tutti i rami del messaggio',
  com_nav_export_recursive_or_sequential: 'Ricorsivo o sequenziale?',
  com_nav_export_recursive: 'Ricorsivo',
  com_nav_export_conversation: 'Esporta la conversazione',
  com_nav_theme: 'Tema',
  com_nav_theme_system: 'Sistema',
  com_nav_theme_dark: 'Scuro',
  com_nav_theme_light: 'Chiaro',
  com_nav_clear: 'Cancella',
  com_nav_clear_all_chats: 'Cancella tutte le chat',
  com_nav_confirm_clear: 'Conferma la cancellazione',
  com_nav_close_sidebar: 'Chiudi la barra laterale',
  com_nav_open_sidebar: 'Apri la barra laterale',
  com_nav_log_out: 'Esci',
  com_nav_user: 'UTENTE',
  com_nav_clear_conversation: 'Cancella le conversazioni',
  com_nav_clear_conversation_confirm_message:
    'Sei sicuro di voler cancellare tutte le conversazioni? Questa operazione è irreversibile.',
  com_nav_help_faq: 'Aiuto e FAQ',
  com_nav_themes: 'Thèmes',
  com_nav_settings: 'Impostazioni',
  com_nav_search_placeholder: 'Cerca messaggi',
  com_nav_setting_general: 'Generale',
};
