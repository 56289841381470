import { atom, selector } from 'recoil';
import { TEndpointsConfig } from 'librechat-data-provider';

const endpointsConfig = atom<TEndpointsConfig>({
  key: 'endpointsConfig',
  default: {
    azureOpenAI: null,
    openAI: null,
    bingAI: null,
    chatGPTBrowser: null,
    gptPlugins: null,
    google: null,
    anthropic: null,
    gemini: null,
    Llama: null,
    claude: null,
    dallE: null,
    Opensource: null
  },
});

const plugins = selector({
  key: 'plugins',
  get: ({ get }) => {
    const config = get(endpointsConfig) || {};
    return config?.gptPlugins?.plugins || {};
  },
});

const endpointsFilter = selector({
  key: 'endpointsFilter',
  get: ({ get }) => {
    const config = get(endpointsConfig) || {};

    const filter = {};
    for (const key of Object.keys(config)) {
      filter[key] = !!config[key];
    }
    // console.log('filter', filter);
    return filter;
  },
});

const availableEndpoints = selector({
  key: 'availableEndpoints',
  get: ({ get }) => {
    const endpoints = [
      'azureOpenAI',
      'openAI',
      'chatGPTBrowser',
      'gptPlugins',
      'bingAI',
      'google',
      'anthropic',
      'gemini',
      'Llama',
      'claude',
      'dallE',
      'Opensource'
    ];
    // console.log('endpoints', endpoints);
    const f = get(endpointsFilter);
    return endpoints.filter((endpoint) => f[endpoint]);
  },
});
// const modelAvailable

const isEndpointMenuOpen = atom({
  key: 'isEndpointMenuOpen',
  default: false,
});

export const activeEndpointState = atom({
  key: 'activeEndpointState',
  default: 'openAI',
});

export default {
  plugins,
  endpointsConfig,
  endpointsFilter,
  availableEndpoints,
  isEndpointMenuOpen,
};

