import React, { useEffect, useState, useRef } from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import { motion } from 'framer-motion';

const ChatExample = ({ clickHandler, text, index, activeEndpoint }) => {
  const textRef = useRef(null);
  const [truncated, setTruncated] = useState([]);
  
  const checkTruncation = () => {
    if (textRef.current) {
      const element = document.getElementById(`model-title-${index}`);
      if (element && element.scrollWidth > element.clientWidth) {
        setTruncated((prevState) => ({ ...prevState, [index]: true }));
      } else {
        setTruncated((prevState) => ({ ...prevState, [index]: false }));
      }
    }
  };

  useEffect(() => {
    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, [text]);

  const variants = {
    hidden: { x: -380 },
    visible: { x: 0 },
  };

  return (
    <>
      {activeEndpoint !== 'dallE' ? (
        <button
          type="button"
          onClick={clickHandler}
          className="ios-2-cells group grid w-full cursor-pointer grid-cols-[auto_auto] text-[12px] items-center justify-start mb-2 px-4 py-2 pr-8 text-left font-semibold relative border border-gray-100 dark:border-gray-600/90 rounded-md text-black dark:text-white/90 hover:opacity-70" 
          aria-label="example prompt"
        >
          <p className="underline-offset-4 text-wrap" aria-label="Example Prompt">
            {text}
          </p>
          <span className="w-[22px] absolute right-[0px] top-[50%]" role="img" aria-label="arrow">
            <FaArrowRight className="inset-1/2 -translate-x-1/2 -translate-y-1/2" role="img" aria-label="arrow" />
          </span>
        </button>
      ) : (
        <motion.div
          className="ios-2-cells group grid w-full cursor-pointer grid-cols-[auto_auto] text-[12px] items-center justify-start mb-2 px-4 py-[8px] pb-[2px] pr-8 text-left font-semibold relative border border-gray-100 dark:border-gray-600/90 rounded-md text-black dark:text-white/90 hover:opacity-70"
        >
          <motion.button
            type="button"
            onClick={clickHandler}
            className="overflow-hidden mr-[30px] truncate"
            aria-label="example prompt"
          >
            <motion.p
              id={`model-title-${index}`}
              ref={textRef}
              className="overflow-hidden inline-block pr-[1px] mr-[4px]"
              variants={variants}
              initial={{ x: 0 }}
              animate={truncated[index] ? "hidden" : "visible"}
              whileHover={truncated[index] ? "visible" : "hidden"}
              transition={{ duration: 2.2, ease: 'linear' }}
            >
              {text}
            </motion.p>
            <span className="w-[22px] absolute right-[0px] top-[50%]" role="img" aria-label="arrow">
              <FaArrowRight className="inset-1/2 -translate-x-1/2 -translate-y-1/2" role="img" aria-label="arrow" />
            </span>
          </motion.button>
        </motion.div>
      )}
    </>
  );
};

export default ChatExample;
