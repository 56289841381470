import type { TPresetItemProps } from '~/common';
import type { TPreset } from 'librechat-data-provider';
import { DropdownMenuRadioItem, EditIcon, TrashIcon } from '~/components';
import { getIcon } from '~/components/Endpoints';

export default function PresetItem({
  preset = {} as TPreset,
  value,
  onSelectPreset,
  onChangePreset,
  onDeletePreset,
}: TPresetItemProps) {
  const { endpoint } = preset;

  const icon = getIcon({
    size: 20,
    endpoint: preset?.endpoint,
    model: preset?.model,
    error: false,
    className: 'flex-none',
  });

  const getPresetTitle = () => {
    let _title = `${endpoint}`;
    const { chatGptLabel, modelLabel, model, jailbreak, toneStyle } = preset;

    if (endpoint === 'azureOpenAI' || endpoint === 'openAI') {
      if (model) {
        _title += `: ${model}`;
      }
      if (chatGptLabel) {
        _title += ` as ${chatGptLabel}`;
      }
    } else if (endpoint === 'google') {
      if (model) {
        _title += `: ${model}`;
      }
      if (modelLabel) {
        _title += ` as ${modelLabel}`;
      }
    } else if (endpoint === 'bingAI') {
      if (toneStyle) {
        _title += `: ${toneStyle}`;
      }
      if (jailbreak) {
        _title += ' as Sydney';
      }
    } else if (endpoint === 'chatGPTBrowser') {
      if (model) {
        _title += `: ${model}`;
      }
    } else if (endpoint === 'gptPlugins') {
      if (model) {
        _title += `: ${model}`;
      }
    } else if (endpoint === null) {
      null;
    } else {
      null;
    }
    return _title;
  };

  const title = `${preset?.title}${!!preset?.title || !!getPresetTitle() ? ' ' : ''}${!!getPresetTitle() ? '(' + getPresetTitle() + ')' : ''}`;

  // regular model
  return (
    <DropdownMenuRadioItem
      /* @ts-ignore, value can be an object as well */
      value={value}
      className="group flex min-h-11 max-h-11 transition-all duration-200 ease-in flex-row items-center gap-x-2 dark:font-semibold dark:text-gray-100 dark:hover:bg-gray-800 sm:h-auto"
      onClick={(e) => {
        e.preventDefault();
        onSelectPreset(preset);
      }}
      title={title}
    >
      {icon}
      <div className="truncate grow-0 text-xs">{title}</div>
      <div className="flex flex-none ml-auto gap-x-1 h-7 sm:h-8 flex-row items-center w-auto sm:w-0 sm:group-hover:w-[68px] sm:group-hover:translate-x-0 sm:group-hover:opacity-100 duration-200 sm:opacity-0 sm:translate-x-full">
        <button
          className="min-h-full rounded-md px-4 block text-gray-400 hover:text-gray-700 dark:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-200 sm:p-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onChangePreset(preset);
          }}
        >
          <EditIcon />
        </button>
        <button
          className="min-h-full rounded-md px-4 block text-gray-400 hover:text-gray-700 dark:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-200 sm:p-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDeletePreset(preset);
          }}
        >
          <TrashIcon />
        </button>
      </div>
    </DropdownMenuRadioItem>
  );
}
