import React from 'react';

export default function Footer() {
  return (
    <div className="hidden px-3 pt-2 text-center text-xs bg:black md:block md:px-4">
      {/* <a
        href="https://ai-pro.org/disclaimer/"
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        AI-PRO is powered by OpenAI. We are neither affiliated nor related to OpenAI.
      </a> */}
    </div>
  );
}
