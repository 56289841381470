import { atom } from 'recoil';
import { TPlugin } from 'librechat-data-provider';

const user = atom({
  key: 'user',
  default: null,
});

const availableTools = atom<TPlugin[]>({
  key: 'availableTools',
  default: [
    {
      name: 'DALL-E',
      pluginKey: 'dall-e',
      description: 'Create realistic images and art from a description in natural language',
      icon: 'https://i.imgur.com/u2TzXzH.png',
      authConfig: [],
    },
  ],
});

export default {
  user,
  availableTools,
};
