import dataURLtoFile from './dataUrlToFile';
/**
 * Resizes an image by converting a base64 string to a resized image File object.
 *
 * @param {string} base64 - The base64 string of the image to be resized.
 * @param {string} filename - The name of the resized image file.
 * @param {string} file_type - The MIME type of the resized image file.
 * @returns {Promise<File>} - A Promise that resolves with the resized image File object.
 */
export const resizeImage = async (
  base64: string,
  filename: string,
  file_type: string,
): Promise<File> => {
  return new Promise<File>((resolve, reject) => {
    if (base64) {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let width = image.width;
        let height = image.height;

        // https://platform.openai.com/docs/guides/vision/calculating-costs
        if (width > 2048 || height > 2048) {
          const maxSize = 2048;
          const aspectRatio = width / height;
          if (aspectRatio > 1) {
            width = maxSize;
            height = parseInt(String(maxSize / aspectRatio));
          } else {
            height = maxSize;
            width = parseInt(String(maxSize * aspectRatio));
          }
        }

        const minSize = 768;
        const aspectRatio = width / height;
        if (width > minSize && height > minSize) {
          if (aspectRatio > 1) {
            height = minSize;
            width = parseInt(String(minSize * aspectRatio));
          } else {
            width = minSize;
            height = parseInt(String(minSize / aspectRatio));
          }
        }

        canvas.width = width;
        canvas.height = height;

        if (ctx) {
          ctx.drawImage(image, 0, 0, width, height);
          const resizedImage: string = canvas.toDataURL(file_type, 0.5);
          const resizedFile = dataURLtoFile(resizedImage, filename);
          resolve(resizedFile);
        } else {
          reject(new Error('Failed to create canvas'));
        }
      };

      image.src = base64;
    }
  });
};
