import React, { useEffect, useContext, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import SubmitButton from './SubmitButton';
import OptionsBar from './OptionsBar';
import { EndpointMenu } from './EndpointMenu';
import Footer from './Footer';
import { useMessageHandler, ThemeContext } from '~/hooks';
import { cn, cookie } from '~/utils';
import global from '~/utils/global';
import store from '~/store';
import Microphone from './Microphone';
import {
  usePopup,
  subscriptionType,
  usePricingPopup,
  useCustomData,
} from '~/components/helper/store';
import FileUploadButton from './FileUpload/FileUploadButton';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { getModelName } from '../helper/modelName';
import { toast } from 'react-toastify';
import { setCookieValue } from '~/components/helper/global';
import { useAppModel } from '../ui/EndPoint';

const openai_gpt4_model = import.meta.env.VITE_OPENAI_GPT4_MODEL || 'gpt-4-1106-preview';
const upgradeUrl = import.meta.env.VITE_UPGRADE_URL || 'https://start.ai-pro.org/upgrade';

export default function TextChat({ isSearchView = false }) {
  const { ask, isSubmitting, handleStopGenerating, latestMessage, endpointsConfig } =
    useMessageHandler();
  const conversation = useRecoilValue(store.conversation);
  const setShowBingToneSetting = useSetRecoilState(store.showBingToneSetting);
  const [text, setText] = useRecoilState(store.text);
  const { theme } = useContext(ThemeContext);
  const isComposing = useRef(false);
  const inputRef = useRef(null);
  const proState = subscriptionType((state) => state);
  const [endpoint, setEndpoint] = useState('');
  const [model, setModel] = useState('');
  const appModel = useAppModel();
  const [maxInputChar, setMaxInputChar] = useState(12000);

  const pState = usePricingPopup((state) => state);

  const {
    hasFile,
    filePreview,
    fileUrls,
    deleteFileUrl,
    deleteAllFileUrls,
    deleteFilePreview,
    deleteAllFile,
  } = useCustomData((state) => state);

  // TODO: do we need this?
  const disabled = false;

  const isNotAppendable = latestMessage?.unfinished & !isSubmitting || latestMessage?.error;
  const { conversationId, jailbreak } = conversation || {};

  // auto focus to input, when enter a conversation.
  useEffect(() => {
    if (!conversationId) {
      return;
    }

    // Prevents Settings from not showing on new conversation, also prevents showing toneStyle change without jailbreak
    if (conversationId === 'new' || !jailbreak) {
      setShowBingToneSetting(false);
    }

    if (conversationId !== 'search') {
      inputRef.current?.focus();
    }
    // setShowBingToneSetting is a recoil setter, so it doesn't need to be in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, jailbreak]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      inputRef.current?.focus();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [isSubmitting]);

  useEffect(() => {
    setEndpoint(conversation.endpoint);
    setModel(conversation.model);
    const models = appModel.find(model => model.model === conversation.model);
    if (models && models.maxInputCharacters) {
      setMaxInputChar(models.maxInputCharacters);

      if (text.length > models.maxInputCharacters) {
        setText((prevText) => prevText.slice(0, models.maxInputCharacters));
      }
    }
  }, [conversation.endpoint, conversation.model]);

  const clickContinue = () => {
    let mixPanelTrack = cookie.getValue('mixPanelTrack');
    let mixPanelClickLocation = cookie.getValue('mixPanelClickLocation');

    if (typeof pState.mixPanelTrack !== 'undefined' && pState.mixPanelTrack != '') {
      mixPanelTrack = pState.mixPanelTrack;
    }

    if (typeof pState.mixPanelClickLocation !== 'undefined' && pState.mixPanelClickLocation != '') {
      mixPanelClickLocation = pState.mixPanelClickLocation;
    }

    if (typeof mixpanel != 'undefined') {
      mixpanel.track('chatpro-upgrade-modal', {
        upgrade: mixPanelTrack,
        'click-location': mixPanelClickLocation,
      });
    }

    if (typeof AIPRO_USER !== 'undefined' && AIPRO_USER && AIPRO_USER.subscription_type) {
      const subscriptionType = AIPRO_USER.subscription_type;
      const payment_interval = AIPRO_USER.payment_interval;

      proState.setType(subscriptionType);
      proState.setPaymentInterval(payment_interval);
    }
  };

  const submitMessage = async () => {
    if (model !== 'gpt-4o') {
      if (hasFile || filePreview?.length > 0 || fileUrls?.length > 0) {
        const formattedModelName = getModelName(model);
        toast.dismiss();
        toast.error(`${formattedModelName} does not support file upload`);
        return;
      }
    }

    if (filePreview?.length !== fileUrls?.length) {
      toast.dismiss();
      toast.error('File upload is inprogress, please wait');
      return;
    }

    setCookieValue('mixPanelTrack', model);

    const availableModels = AIPRO_USER.ai_models;
    const isNotAvailableModel = !availableModels.includes(model);
    if(isNotAvailableModel) {
      clickContinue();
      global.showPricing(() => {
        pState.setShowPricingPopup(true);
      });
      return;
    }

    if (typeof IS_FREE_USER !== 'undefined' && IS_FREE_USER) {
      // moved to backend
    } else {
      btutil_getChatUsage('chatbotpro');
      let maxTokens = btutilCommon_getCookie('mucnxwlyxt');
      if (maxTokens !== undefined && maxTokens !== '') {
        return;
      }
    }

    ask({ text, fileUrls: fileUrls });

    setText('');
    deleteAllFileUrls();
    deleteAllFile();

    const uploadImage = document.getElementById('upload-file');
    if (uploadImage) {
      uploadImage.value = '';
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && isSubmitting) {
      return;
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
    }

    if (e.key === 'Enter' && !e.shiftKey && !isComposing?.current) {
      submitMessage();
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 8 && e.target.value.trim() === '') {
      setText(e.target.value);
    }

    if (e.key === 'Enter' && e.shiftKey) {
      return console.log('Enter + Shift');
    }

    if (isSubmitting) {
      return;
    }
  };

  const handleCompositionStart = () => {
    isComposing.current = true;
  };

  const handleCompositionEnd = () => {
    isComposing.current = false;
  };

  const changeHandler = (e) => {
    const { value } = e.target;

    setText(value);
  };

  const getPlaceholderText = () => {
    if (isSearchView) {
      return 'Click a message title to open its conversation.';
    }

    if (disabled) {
      return 'Choose another model or customize GPT again';
    }

    if (isNotAppendable) {
      return 'Edit your message or Regenerate.';
    }

    return 'Enter a message';
  };

  if (isSearchView) {
    return <></>;
  }

  let isDark = theme === 'dark';

  if (theme === 'system') {
    isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  const showFileUpload = conversation && conversation.endpoint === 'openAI' && conversation.model === 'gpt-4o';
  // console.log("conversation.endpoint:", conversation.endpoint);
  // console.log("conversation.model:", conversation.model);

  return (
    <>
      <div className="textchat-container no-gradient-sm fixed bottom-0 left-0 w-full sm:bg-gradient-to-b  absolute">
        {conversationId !== 'new' || conversation.endpoint !== 'openAI' ? <OptionsBar /> : null}
        <div className="input-panel relative w-full border-t bg-[#f7f7f8] py-2 dark:border-white/20 dark:bg-[#1e1e1e] md:border-t-0">
          <form className="stretch z-[60] mx-auto flex w-full max-w-full flex-row gap-3 px-2.5 last:mb-2 lg:max-w-2xl lg:p-0 xl:max-w-3xl">
            <div className="relative flex h-full w-full flex-1 flex-col">
              <label
                htmlFor="text-input-odfigj"
                className={cn(
                  'rounded-xl border border-black/10',
                  'shadow-[0_0_15px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)]',
                  'dark:border-gray-900/50 dark:text-white',
                  disabled ? 'bg-gray-100 dark:bg-gray-900' : 'bg-white dark:bg-gray-700',
                )}
              >
                {filePreview && filePreview.length > 0 && (
                  <div
                    className={cn(
                      'flex flex-row overflow-auto pb-1 pl-2 pt-2 md:gap-2 md:pl-4 md:pr-2 md:pt-4',
                    )}
                  >
                    {filePreview.map((file, index) => {
                      const id = file.id;
                      const isLoading = file.isLoading;
                      const uploaded = file.uploaded;
                      const originalName = file.originalName;
                      const isNOTimage = !file.file_type.includes('image');

                      return (
                        <div key={index}>
                          <div
                            style={{ maxWidth: '12rem' }}
                            className="group relative mr-2 flex items-center justify-center rounded-xl border border-black/10 p-2 dark:border-gray-900/50"
                          >
                            {uploaded && (
                              <IoIosCloseCircleOutline
                                onClick={() => {
                                  deleteFilePreview(id);
                                  deleteFileUrl(id);
                                }}
                                title="delete file"
                                className="absolute -top-1 right-0 -mr-1 flex hidden cursor-pointer rounded-full border bg-red-500  text-white group-hover:flex"
                              />
                            )}
                            <img
                              key={id}
                              className={cn(
                                'h-10 rounded-md border border-black/10',
                                isLoading && 'opacity-30',
                              )}
                              style={{ minWidth: '2.5rem' }}
                              src={file.base64}
                              alt={`Upload Preview ${index}`}
                            />
                            {originalName && isNOTimage && (
                              <div className="ml-2 flex w-8/12 flex-col text-sm">
                                <p className={cn('truncate font-bold', isLoading && 'opacity-30')}>
                                  <span className="truncate">{originalName}</span>
                                </p>
                                <p className="opacity-50">{originalName.split('.').pop()}</p>
                              </div>
                            )}

                            {isLoading && (
                              <AiOutlineLoading3Quarters className="absolute animate-spin" />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div
                  className={cn(
                    'input-container relative flex w-full flex-grow flex-row rounded-xl py-[10px] pl-2 md:py-4 md:pl-4',
                  )}
                >
                  {text.length === maxInputChar && (
                    <div id="customTooltip" className="custom-tooltip">Too many characters</div>
                  )}
                  <div className="flex items-end space-x-2 mb-[10px]">
                    <EndpointMenu />
                    <Microphone />
                    {showFileUpload ? <FileUploadButton /> : null}
                  </div>
                  <TextareaAutosize
                    // set test id for e2e testing
                    id="text-input-odfigj"
                    data-testid="text-input"
                    tabIndex="0"
                    autoFocus
                    ref={inputRef}
                    // style={{maxHeight: '200px', height: '24px', overflowY: 'hidden'}}
                    rows="1"
                    value={disabled || isNotAppendable ? '' : text}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                    onChange={changeHandler}
                    onCompositionStart={handleCompositionStart}
                    onCompositionEnd={handleCompositionEnd}
                    placeholder={getPlaceholderText()}
                    disabled={disabled || isNotAppendable}
                    className="m-0 ml-2 mr-12 flex h-auto max-h-52 flex-1 resize-none overflow-auto border-0 bg-transparent p-0 pl-1 leading-6 placeholder:text-gray-600 focus:ring-0 focus-visible:ring-0 dark:bg-transparent dark:placeholder:text-gray-500 md:ml-2 outline-none"
                    aria-label="Text input"
                    maxLength={maxInputChar}
                  />

                  <SubmitButton
                    submitMessage={submitMessage}
                    handleStopGenerating={handleStopGenerating}
                    disabled={disabled || isNotAppendable}
                    isSubmitting={isSubmitting}
                    isTyping={!!text || !!filePreview?.length || !!fileUrls?.length}
                    endpointsConfig={endpointsConfig}
                    endpoint={conversation?.endpoint}
                  />
                </div>
              </label>
            </div>
          </form>
          <Footer />
        </div>
      </div>
    </>
  );
}
