import { toast } from 'react-toastify';
import { useCustomData } from '~/components/helper/store';
import { fileLimit } from './DropzoneOptions';

export default function FileUploadButton() {
  const filePreview = useCustomData((state) => state.filePreview);

  const onClick = () => {
    if (filePreview.length >= fileLimit) {
      toast.dismiss();
      toast.error(`You can only upload up to ${fileLimit} file${fileLimit > 1 ? 's' : ''}`);
    }
  };
  return (
    <>
      <label
        htmlFor="upload-file"
        className="relative mb-[-12px] mr-1 mt-[-4px] flex h-[32px] cursor-pointer items-center rounded bg-transparent px-1 text-xs font-medium font-normal transition-colors hover:bg-slate-200 dark:bg-transparent dark:text-gray-300 dark:hover:bg-gray-800"
        role="button"
        aria-label="Upload File"
        title="Upload File"
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9 7a5 5 0 0 1 10 0v8a7 7 0 1 1-14 0V9a1 1 0 0 1 2 0v6a5 5 0 0 0 10 0V7a3 3 0 1 0-6 0v8a1 1 0 1 0 2 0V9a1 1 0 1 1 2 0v6a3 3 0 1 1-6 0z"
            clipRule="evenodd"
          ></path>
        </svg>
      </label>
    </>
  );
}
