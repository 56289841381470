import React from 'react';
import { motion } from 'framer-motion';
import { usePricingPopup } from '../helper/store.js';
import { HiSparkles } from "react-icons/hi2";

const descriptionVariants = {
  hidden: { x: -20, opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { duration: 0.3 } },
};

const ChatModelDescription = ({ title, token, children, disabled }) => {
  const pState = usePricingPopup((state) => state);
  return (
    <>
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={descriptionVariants}
        className='min-h-[145px]'
      >
        <div className="grid w-fit grid-cols-[auto_auto] items-center gap-4 text-base font-bold text-[20px]">
          <span role="contentinfo" aria-label={title} className='hidden md:block'>
            {title}
          </span>
          {token && <p aria-label="token" className='text-[12px] text-gray-300 pt pb-4 inline-block md:hidden'>Context token: {token}</p>}
          {disabled && (
            <button
              className="rounded-2xl bg-yellow-500 mb-[20px] px-2 py-1 text-xs font-semibold tracking-wider text-white"
              onClick={() => {
                showPricing(()=>{
                  pState.setShowPricingPopup(true);
                });
              }}
              title={disabled}
              type="button"
            >
              {disabled} <HiSparkles className='inline'/>
            </button>
          )}
        </div>
        {token && <p aria-label="token" className='text-[12px] text-gray-600 dark:text-gray-300 pt pb-4 hidden md:block'>Context token: {token}</p>}
        <p className="text-gray-600/90 dark:text-inherit min-h-[80px]" aria-label="">
          {children}
        </p>
      </motion.div>
    </>
  );
};

export default ChatModelDescription;
