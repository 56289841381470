export default function Preview() {
  return (
    <>
      <div className="preview">
        <div className="preview-sidebar">
          <div className="sidebar-row">
            <div className="preview-placeholder" style={{ width: '30px' }}></div>
          </div>
          <div className="sidebar-row active">
            <div className="preview-placeholder" style={{ width: '57px' }}></div>
          </div>
          <div className="sidebar-row">
            <div className="preview-placeholder" style={{ width: '48px' }}></div>
          </div>
          <div className="sidebar-row"></div>
          <div className="sidebar-row"></div>
          <div className="sidebar-row">
            <div className="preview-placeholder"></div>
          </div>
          <div className="sidebar-row">
            <div className="preview-placeholder"></div>
          </div>
          <div className="sidebar-row">
            <div className="preview-placeholder"></div>
          </div>
        </div>
        <div className="preview-content">
          <div>
            <div className="sidebar-row">
              <div className="preview-placeholder" style={{ width: '30px' }}></div>
            </div>

            <div className="sidebar-row">
              <div className="preview-placeholder" style={{ width: '100%' }}></div>
            </div>
          </div>

          <div className="sidebar-row">
            <div className="preview-placeholder" style={{ width: '100%' }}></div>
          </div>
        </div>
      </div>
    </>
  );
}
