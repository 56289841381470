import { useRecoilState } from 'recoil';
import { useState, useEffect, useRef } from 'react';
import { ChevronDownIcon } from 'lucide-react';
import { useAvailablePluginsQuery, TPlugin } from 'librechat-data-provider';
import type { TModelSelectProps } from '~/common';
import { SelectDropDown, MultiSelectDropDown, Button } from '~/components/ui';
import { useSetOptions, useAuthContext, useMediaQuery } from '~/hooks';
import { cn, cardStyle } from '~/utils/';
import store from '~/store';

const pluginStore: TPlugin = {
  name: 'Plugin store',
  pluginKey: 'pluginStore',
  isButton: true,
  description: '',
  icon: '',
  authConfig: [],
  authenticated: false,
};

export default function Plugins({ conversation, setOption, models }: TModelSelectProps) {
  const { data: allPlugins } = useAvailablePluginsQuery();
  const [visible, setVisibility] = useState<boolean>(true);
  const [availableTools, setAvailableTools] = useRecoilState(store.availableTools);
  const { checkPluginSelection, setTools } = useSetOptions();
  const { user } = useAuthContext();
  const isSmallScreen = useMediaQuery('(max-width: 640px)');
  const [showPopover, setShowPopover] = useRecoilState(store.showPopover);

  useEffect(() => {
    if (isSmallScreen) {
      setVisibility(false);
    } else {
      setVisibility(true);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    if (showPopover && isSmallScreen) {
      setVisibility(false);
    }
  }, [showPopover]);

  useEffect(() => {
    if (visible) {
      setShowPopover(false);
    }
  }, [visible]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!allPlugins) {
      return;
    }

    if (!user.plugins || user.plugins.length === 0) {
      setAvailableTools([pluginStore]);
      return;
    }

    const tools = [...user.plugins]
      .map((el) => allPlugins.find((plugin: TPlugin) => plugin.pluginKey === el))
      .filter((el): el is TPlugin => el !== undefined);

    /* Filter Last Selected Tools */
    const localStorageItem = localStorage.getItem('lastSelectedTools');
    if (!localStorageItem) {
      return setAvailableTools([...tools, pluginStore]);
    }
    const lastSelectedTools = JSON.parse(localStorageItem);
    const filteredTools = lastSelectedTools.filter((tool: TPlugin) =>
      tools.some((existingTool) => existingTool.pluginKey === tool.pluginKey),
    );
    localStorage.setItem('lastSelectedTools', JSON.stringify(filteredTools));

    setAvailableTools([...tools, pluginStore]);
    // setAvailableTools is a recoil state setter, so it's safe to use it in useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPlugins, user]);

  if (!conversation) {
    return null;
  }

  return (
    <div className="flex items-center">
      <Button
        type="button"
        className={cn(
          cardStyle,
          'z-40 flex h-[40px] min-w-4 flex-none items-center justify-center px-3 hover:bg-white focus:ring-0 focus:ring-offset-0 dark:hover:bg-gray-700',
          isSmallScreen ? '' : 'hidden',
        )}
        onClick={() => setVisibility(!visible)}
      >
        <ChevronDownIcon
          className={cn(visible ? 'transform' : 'rotate-180', 'w-4 text-gray-600 dark:text-white')}
        />
      </Button>
      <div
        className={cn(
          'absolute bottom-14 left-1/2 flex -translate-x-1/2 items-center gap-2 transition-all duration-300 ease-in sm:relative sm:bottom-auto',
          visible ? '' : 'pointer-events-none translate-y-[200%] opacity-0',
        )}
      >
        <SelectDropDown
          value={conversation.model ?? ''}
          setValue={setOption('model')}
          availableValues={models}
          showAbove={true}
          showLabel={false}
          className={cn(
            cardStyle,
            'z-50 ml-2 flex h-10 w-48 min-w-48 flex-none items-center justify-center px-4 hover:cursor-pointer',
          )}
        />
        <MultiSelectDropDown
          value={conversation.tools || []}
          isSelected={checkPluginSelection}
          setSelected={setTools}
          availableValues={availableTools}
          optionValueKey="pluginKey"
          showAbove={true}
          showLabel={false}
          className={cn(
            cardStyle,
            'z-50 flex h-10 w-28 flex-none items-center justify-center px-4 hover:cursor-pointer [&~ul]:left-1/2 [&~ul]:min-w-36 [&~ul]:-translate-x-1/2',
          )}
        />
      </div>
    </div>
  );
}
