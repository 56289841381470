import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import store from '~/store';
import { usePopup, usePricingPopup, subscriptionType } from '~/components/helper/store';
import { IoStop } from 'react-icons/io5';
import global from '~/utils/global';
import { setCookieValue } from '~/components/helper/global';

export default function Microphone() {
  const setText = useSetRecoilState(store.text);
  const [showPopover, setShowPopover] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isNotAvailable, setIsNotAvailable] = useState(false);

  const [userMediaStream, setUserMediaStream] = useState(null);
  const sState = usePopup((state) => state);
  const proState = subscriptionType((state) => state);
  const pState = usePricingPopup((state) => state);
  const SupportedType = (media = 'audio') => {
    const extensions = ['webm', 'ogg', 'mp3', 'x-matroska', 'mp4', 'mpeg', 'mpga', 'm4a', 'wav']; // audio/mp4 fallback for safari
    const codecs = [
      'should-not-be-supported',
      'vp9',
      'vp9.0',
      'vp8',
      'vp8.0',
      'avc1',
      'av1',
      'h265',
      'h.265',
      'h264',
      'h.264',
      'opus',
      'pcm',
      'aac',
      'mpeg',
      'mp4a',
    ];
    const isSupported = MediaRecorder.isTypeSupported;
    const allSupportedType = [];
    extensions.forEach((extension) => {
      const mimeType = `${media}/${extension}`;

      codecs.forEach((codec) => {
        if (isSupported(`${mimeType};codecs=${codec}`)) {
          allSupportedType.push({
            media,
            extension,
            codecs: codec,
            mimeType: `${mimeType};codecs=${codec}`,
          });
        }

        if (isSupported(`${mimeType};codecs=${codec.toUpperCase()}`)) {
          allSupportedType.push({
            media,
            extension,
            codecs: codec,
            mimeType: `${mimeType};codecs=${codec.toUpperCase()}`,
          });
        }
      });

      if (isSupported(mimeType)) {
        allSupportedType.push({
          media,
          extension,
          mimeType,
        });
      }
    });

    return {
      allSupportedType: allSupportedType,
      supportedType: allSupportedType?.[0] || [],
    };
  };
  async function process(audio, extension) {
    if (audio != null) {
      const controller = new AbortController();
      const signal = controller.signal;
      const speech_response = await fetch('/api/ask/openAI/whisper', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          audio: audio ? audio : null,
          extension: extension ? extension : 'webm',
        }),
        signal, // Pass the signal option to the fetch request
      });
      const response = await speech_response.json();
      const response_text = response.text;

      const text = document.querySelector('#text-input-odfigj').value;
      console.log('TEXT', text);
      console.log('RES TEXT', response_text);
      const final_text = text + ' ' + response_text;
      setText(final_text);
    }
  }
  const onClick = async () => {
    if (typeof AIPRO_USER !== 'undefined' && AIPRO_USER && AIPRO_USER.subscription_type) {
      const subscriptionType = AIPRO_USER.subscription_type;

      if (subscriptionType) {
        if (subscriptionType.toString().toLowerCase() === 'basic') {
          sState.setMixPanelTrack('');
          sState.setMixPanelClickLocation('microphone');
          setCookieValue('mixPanelTrack', '');
          setCookieValue('mixPanelClickLocation', 'microphone');
          global.showPricing(()=>{
            pState.setShowPricingPopup(true);
          });
          return;
        }
      }
    }

    if (isListening) {
      setIsListening(false);

      userMediaStream?.getAudioTracks().forEach((track) => {
        track.stop();
      });
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setIsListening(true);
        setUserMediaStream(stream);
        let interval;
        let audioChunks = [];
        let recording = false;
        let stoppedTalking = false;
        let stoppedTalkingInterval = 0;
        const autoEndTimer = import.meta.env.VITE_MIC_DURATION || 10000;
        let recordingInterval = 0;
        const activityDetectionThreshold = 10;
        const micQuiet = 1000;

        const audioContext = new AudioContext();
        const gainNode = audioContext.createGain();
        const analyzer = audioContext.createAnalyser();

        const source = audioContext.createMediaStreamSource(stream);
        source.connect(gainNode);
        gainNode.connect(analyzer);
        const bufferLength = analyzer.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const { supportedType } = SupportedType();
        const { mimeType, extension = 'webm' } = supportedType;

        const mediaRecorder = new MediaRecorder(stream, { type: mimeType });

        mediaRecorder.addEventListener('dataavailable', (event) => {
          console.log('111');
          audioChunks = [...audioChunks, event.data];
        });

        mediaRecorder.addEventListener('stop', async () => {
          console.log('stop', audioChunks);
          // clearInterval(interval);
          const audioBlob = new Blob(audioChunks, { type: mimeType });
          audioChunks = [];
          const reader = new FileReader();
          reader.readAsDataURL(audioBlob);
          reader.onloadend = async function () {
            const base64audio = reader.result;
            process(base64audio, extension);
          };
        });

        interval = setInterval(() => {
          analyzer.getByteFrequencyData(dataArray);
          const volume = dataArray.reduce((acc, val) => acc + val) / bufferLength;
          if (volume > activityDetectionThreshold) {
            if (!recording) {
              recording = true;
              mediaRecorder.start();
              console.log('Start recording, substantial audio detected');
            } else {
              console.log('User is currently talking');
              recordingInterval += 100;
              if (recordingInterval > autoEndTimer) {
                console.log('User has been talking for too long, stopping recording');
                recordingInterval = 0;
                recording = false;
                mediaRecorder.stop();
                setTimeout(() => {
                  setIsListening(false);
                  clearInterval(interval);
                });
              }
            }
          } else {
            // console.log('No audio detected.');
            if (recording) {
              stoppedTalking = true;
              stoppedTalkingInterval += 100;
              console.log('User is not currently talking');
            }
            if (stoppedTalking && stoppedTalkingInterval > micQuiet) {
              recording = false;
              stoppedTalking = false;
              stoppedTalkingInterval = 0;
              mediaRecorder.stop();
              console.log('Stopped recording, user has stopped talking');
              setTimeout(() => {
                setIsListening(false);
                clearInterval(interval);
              });
            }
          }
        }, 100);
      } catch (error) {
        setIsNotAvailable(true);
        console.log(error);
        return;
      }
    }
  };

  return (
    <>
      <div
        onClick={onClick}
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
        className={`mic-btn group relative mx-2 mb-[-8px] mt-[-4px] flex
        h-[32px] cursor-pointer  items-center justify-center rounded-full border p-2
        ${isListening ? 'bg-black' : isNotAvailable ? 'bg-red-600' : 'bg-white'}
        `}
        role="button"
        aria-label="Microphone"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onClick();
          }
        }}
      >
        {isNotAvailable ? (
          <FaMicrophoneSlash className="text-white" role="button" aria-label="Microphone" />
        ) : isListening ? (
          <IoStop className="text-white" role="button" aria-label="stop" />
        ) : (
          <FaMicrophone className="text-gray-600" role="button" aria-label="Microphone" />
        )}
        {(showPopover || isListening) && (
          <Popover isListening={isListening} isNotAvailable={isNotAvailable} />
        )}
      </div>
    </>
  );
}

function Popover({ isListening, isNotAvailable }) {
  let bgColor = isListening ? 'black' : 'white';
  let color = isListening ? 'white' : 'black';
  if (isNotAvailable) {
    bgColor = 'rgb(220 38 38)';
    color = 'white';
  }

  return (
    <>
      <div
        style={{
          margin: 0,
          position: 'absolute',
          top: '-45px',
          left: isListening ? '-20px' : '-30px',
          minWidth: 'max-content',
          width: 'max-content',
          borderRadius: '5px',
          backgroundColor: bgColor,
          color: color,
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          padding: '5px',
          zIndex: '70',
        }}
      >
        {isListening ? (
          <p className="text-xs font-semibold">Listening...</p>
        ) : isNotAvailable ? (
          <p className="text-xs font-semibold">Dictation is not available</p>
        ) : (
          <p className="text-xs font-semibold">Click to dictate</p>
        )}
        <span
          style={{
            position: 'absolute',
            bottom: 0,
            transform: 'translateY(100%)',
            left: isListening ? '32px' : '42px',
          }}
        >
          <svg
            width={10}
            height={5}
            viewBox="0 0 30 10"
            preserveAspectRatio="none"
            style={{ display: 'block' }}
            fill={bgColor}
          >
            <polygon points="0,0 30,0 15,10" />
          </svg>
        </span>
      </div>
    </>
  );
}
