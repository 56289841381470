import { useCallback, useState } from 'react';
import { v4 } from 'uuid';
import { useCustomData } from '~/components/helper/store';
import { useDropzone } from 'react-dropzone';
import { resizeImage } from './resizeImage';
import saveFile from './saveFile';
import fileReader from './FileReader';
import countImageToken from './countImageToken';
import { TFilePreview, TFileUrls } from '~/common';
import { FaRegFileWord } from 'react-icons/fa';
import { FaRegFilePdf } from 'react-icons/fa6';
import { FaRegFileImage } from 'react-icons/fa';
import { RiFilePpt2Line } from 'react-icons/ri';
import { GrDocumentTxt } from 'react-icons/gr';
import { toast } from 'react-toastify';

import {
  fileLimit,
  DropzoneOptions,
  onError,
  validator as _validator,
  clearFile,
  validateFile,
  FileRejection,
} from './DropzoneOptions';
import { cn } from '~/utils';
import convertFile from './convertFile';

export default function Dropzone({ className = '', children }) {
  const {
    filePreview,
    addFilePreview,
    addFileUrls,
    updateFilePreviewStatus,
    getFilePreview,
    deleteFilePreview,
  } = useCustomData((state) => state);

  const [showDropzonePlaceholder, setShowDropzonePlaceholder] = useState(false);

  const _addFilePreview = (id: string, base64: string, file_name: string, file_type: string) => {
    const newFilePreview: TFilePreview = {
      id,
      base64,
      file_name,
      isLoading: true,
      uploaded: false,
      file_type,
      originalName: file_name,
    };
    addFilePreview(newFilePreview);
  };

  const _addFileUrls = ({
    id,
    url,
    file_name,
    file_type,
    token,
    originalName,
  }: {
    id: string;
    url: string | [];
    file_name?: string;
    file_type?: string;
    token?: number;
    originalName?: string;
  }) => {
    const newFileUrls: TFileUrls = {
      id,
      url,
      file_name,
      url_timestamp: Date.now(),
      url_expires: Date.now() + 10080 * 60 * 1000, // 7 days
      file_type,
      token: token,
      originalName: originalName,
    };

    addFileUrls(newFileUrls);
  };

  const onDragEnter = useCallback(() => {
    setShowDropzonePlaceholder(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setShowDropzonePlaceholder(false);
  }, []);

  const validator = (file: File) => {
    return _validator(file, getFilePreview());
  };

  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setShowDropzonePlaceholder(false);
    const files: File[] = acceptedFiles;
    if (!validateFile(files, fileRejections, getFilePreview())) {
      clearFile();
      return;
    }

    Array.from(files).forEach(async (file: File) => {
      const id: string = v4();
      if (file.type.includes('image')) {
        const base64: string = await fileReader(file);
        _addFilePreview(id, base64, file.name, file.type);
        const resizedImage = await resizeImage(base64, file.name, file.type);
        let token = 0;
        if (resizedImage) {
          const resizedImageBase64: string = await fileReader(resizedImage);
          const imageToken: number = await countImageToken(resizedImageBase64);
          token = imageToken;
        }

        const { status, url, file_name } = await saveFile(resizedImage, id);
        if (status == 'success') {
          updateFilePreviewStatus(id);
          _addFileUrls({
            id,
            url,
            file_name,
            file_type: file.type,
            token: token,
            originalName: file.name,
          });
        } else {
          deleteFilePreview(id);
          toast.error('Failed to upload file, please try again');
        }
      } else if (file.type == 'text/plain') {
        const preview = '/assets/txt.svg';
        _addFilePreview(id, preview, file.name, file.type);
        const { status, url, file_name } = await saveFile(file, id);
        if (status == 'success') {
          updateFilePreviewStatus(id);
          _addFileUrls({ id, url, file_name, file_type: file.type, originalName: file.name });
        } else {
          deleteFilePreview(id);
          toast.error('Failed to upload file, please try again');
        }
      } else {
        let preview = '/assets/file.svg';
        if (file.type.includes('pdf')) {
          preview = '/assets/pdf.svg';
        }
        if (file.type.includes('word')) {
          preview = '/assets/word.svg';
        }
        if (file.type.includes('presentation') || file.type.includes('powerpoint')) {
          preview = '/assets/pptx.svg';
        }
        _addFilePreview(id, preview, file.name, file.type);
        try {
          const { status, urls: convertedFileUrls } = await convertFile(file, id);
          if (status == 'success') {
            let urls: any = [];
            let token = 0;

            if (convertedFileUrls && convertedFileUrls.length > 0) {
              const promises = convertedFileUrls.map(async ({ file_name, status, url }) => {
                const resizedImage = await resizeImage(url, file_name, file.type);
                const resizedImageBase64: string = await fileReader(resizedImage);
                const imageToken: number = await countImageToken(resizedImageBase64);
                console.log('imageToken', imageToken);
                token += imageToken;
                const res = await saveFile(resizedImage, id);
                urls.push({
                  status: res.status,
                  url: res.url,
                  file_name: res.file_name,
                });
              });
              await Promise.all(promises);
            }

            updateFilePreviewStatus(id);
            _addFileUrls({
              id,
              url: urls,
              file_name: file.name,
              file_type: file.type,
              token: token,
              originalName: file.name,
            });
          } else {
            deleteFilePreview(id);
            toast.error('Failed to upload file, please try again');
          }
        } catch (error) {
          console.error(error);
          deleteFilePreview(id);
          toast.error('Failed to upload file, please try again');
        }
      }
    });

    clearFile();
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    ...DropzoneOptions,
    onDragEnter,
    onDragLeave,
    validator,
    onDrop,
    onError,
  });

  return (
    <div {...getRootProps({ className })}>
      <input id="upload-file" {...getInputProps()} disabled={filePreview.length >= fileLimit} />
      {children}
      {showDropzonePlaceholder && (
        <div
          className={cn(
            'absolute z-[103] flex h-full w-full flex-col items-center justify-center border-2 bg-slate-50 bg-opacity-70',
          )}
        >
          <div className="flex items-end">
            <FaRegFilePdf className="h-14 w-14 text-red-500" />
            <FaRegFileImage className="h-14 w-14 text-green-500 " />
            <FaRegFileWord className="h-14 w-14 text-blue-500" />
            <RiFilePpt2Line className="h-14 w-14 text-orange-500" />
            <GrDocumentTxt className="h-14 w-14" />
          </div>
          <p className="mb-2 mt-4 text-center text-lg font-semibold">Drag and drop here</p>
          <p className="text-center text-sm">Drop file here to add it to the conversation</p>
        </div>
      )}
    </div>
  );
}
