import { forwardRef, useEffect, useRef, useState } from 'react';
import { cn } from '~/utils/';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { debounce } from 'lodash';

const NavDropdown = forwardRef((props, ref) => {
  const { dropdownOpen, setDropdownOpen, svg, text, className = '', children, ...rest } = props;
  // const [open, setOpen] = useState(false);
  const [init, setInit] = useState(false);
  const [optionsHeight, setOptionsHeight] = useState(0);
  const optionsRef = useRef(null);

  useEffect(() => {
    const options = optionsRef.current;

    const handleResize = () => {
      if (options) {
        setOptionsHeight(options.scrollHeight);
      }
    };

    const debouncedResizeHandler = debounce(handleResize, 200);

    handleResize();
    window.addEventListener('resize', debouncedResizeHandler);

    const timeoutInit = setTimeout(() => setInit(true), 200);

    return () => {
      window.removeEventListener('resize', debounce(handleResize, 200));
      clearTimeout(timeoutInit);
      setInit(false);
    };
  }, []);

  return (
    <>
      <button
        aria-expanded={dropdownOpen}
        data-state={dropdownOpen ? 'open' : 'closed'}
        className={cn(
          'nav-menu-dropdown flex w-full cursor-pointer items-center gap-3 rounded-none px-3 py-3 text-left text-sm text-white transition-colors duration-200',
          dropdownOpen ? 'bg-gray-500/10 hover:bg-gray-500/10' : 'hover:bg-gray-700',
          className,
        )}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        ref={ref}
        type="button"
        {...rest}
      >
        {svg}
        {text}
        {dropdownOpen ? (
          <ChevronDown size={20} role="img" aria-label={text} className="ml-auto" />
        ) : (
          <ChevronUp size={20} role="img" aria-label={text} className="ml-auto" />
        )}
      </button>
      <div
        className={cn(
          'block w-full overflow-hidden',
          init ? 'transition-all duration-200' : '',
          dropdownOpen ? 'bg-gray-500/10' : '',
        )}
        ref={optionsRef}
        style={{
          height: dropdownOpen ? `${optionsHeight}px` : '0',
        }}
      >
        {children}
      </div>
    </>
  );
});

export default NavDropdown;
