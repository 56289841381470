export default [
  {
    value: 'Recommend',
    label: 'Recommend',
    group: 'Recommend',
  },
  {
    value: 'English',
    label: 'English',
    group: 'Recommend',
  },
  {
    value: 'Deutsch',
    label: 'Deutsch',
    group: 'Recommend',
  },
  {
    value: 'Español',
    label: 'Español',
    group: 'Recommend',
  },
  {
    value: 'Português',
    label: 'Português',
    group: 'Recommend',
  },
  {
    value: 'العربية',
    label: 'العربية',
    group: 'Recommend',
  },
  {
    value: '中文',
    label: '中文',
    group: 'Recommend',
  },
  {
    value: '繁體中文',
    label: '繁體中文',
    group: 'Recommend',
  },
  {
    value: 'Français',
    label: 'Français',
    group: 'Recommend',
  },
  {
    value: 'Italiano',
    label: 'Italiano',
    group: 'Recommend',
  },
  {
    value: '日本語',
    label: '日本語',
    group: 'Recommend',
  },
  {
    value: '한국어',
    label: '한국어',
    group: 'Recommend',
  },
  {
    value: 'Bahasa Melayu',
    label: 'Bahasa Melayu',
    group: 'Recommend',
  },
  {
    value: 'Nederlands',
    label: 'Nederlands',
    group: 'Recommend',
  },
  {
    value: 'Bahasa Indonesia',
    label: 'Bahasa Indonesia',
    group: 'Recommend',
  },
  {
    value: 'فارسی',
    label: 'فارسی',
    group: 'Recommend',
  },
  {
    value: 'हिन्दी',
    label: 'हिन्दी',
    group: 'Recommend',
  },
  {
    value: 'বাংলা',
    label: 'বাংলা',
    group: 'Recommend',
  },
  {
    value: 'Русский',
    label: 'Русский',
    group: 'Recommend',
  },
  {
    value: 'All',
    label: 'All',
    group: 'All',
  },
  {
    value: 'Afrikaans',
    label: 'Afrikaans',
    group: 'All',
  },
  {
    value: 'Shqip',
    label: 'Shqip',
    group: 'All',
  },
  {
    value: 'العربية',
    label: 'العربية',
    group: 'All',
  },
  //
  {
    value: 'Հայերեն',
    label: 'Հայերեն',
    group: 'All',
  },
  {
    value: 'Azərbaycan dili',
    label: 'Azərbaycan dili',
    group: 'All',
  },
  //
  {
    value: 'Euskara',
    label: 'Euskara',
    group: 'All',
  },
  {
    value: 'Беларуская мова',
    label: 'Беларуская мова',
    group: 'All',
  },
  {
    value: 'বাংলা',
    label: 'বাংলা',
    group: 'All',
  },
  {
    value: 'Bosanski jezik',
    label: 'Bosanski jezik',
    group: 'All',
  },
  {
    value: 'Български език',
    label: 'Български език',
    group: 'All',
  },
  {
    value: 'Català',
    label: 'Català',
    group: 'All',
  },
  {
    value: 'Chichewa',
    label: 'Chichewa',
    group: 'All',
  },
  {
    value: '中文',
    label: '中文',
    group: 'All',
  },
  {
    value: 'Hrvatski jezik',
    label: 'Hrvatski jezik',
    group: 'All',
  },
  {
    value: 'Čeština',
    label: 'Čeština',
    group: 'All',
  },
  {
    value: 'Dansk',
    label: 'Dansk',
    group: 'All',
  },
  {
    value: 'Nederlands',
    label: 'Nederlands',
    group: 'All',
  },
  {
    value: 'English',
    label: 'English',
    group: 'All',
  },
  {
    value: 'English (UK)',
    label: 'English (UK)',
    group: 'All',
  },
  {
    value: 'English (US)',
    label: 'English (US)',
    group: 'All',
  },
  {
    value: 'Esperanto',
    label: 'Esperanto',
    group: 'All',
  },
  {
    value: 'Eesti keel',
    label: 'Eesti keel',
    group: 'All',
  },
  {
    value: 'Suomi',
    label: 'Suomi',
    group: 'All',
  },
  {
    value: 'Français',
    label: 'Français',
    group: 'All',
  },
  {
    value: 'Galego',
    label: 'Galego',
    group: 'All',
  },
  //
  {
    value: 'ქართული',
    label: 'ქართული',
    group: 'All',
  },
  {
    value: 'Deutsch',
    label: 'Deutsch',
    group: 'All',
  },
  {
    value: 'Ελληνικά',
    label: 'Ελληνικά',
    group: 'All',
  },
  {
    value: 'ગુજરાતી',
    label: 'ગુજરાતી',
    group: 'All',
  },
  {
    value: 'Kreyòl Ayisyen',
    label: 'Kreyòl Ayisyen',
    group: 'All',
  },
  {
    value: 'עברית',
    label: 'עברית',
    group: 'All',
  },
  {
    value: 'हिन्दी',
    label: 'हिन्दी',
    group: 'All',
  },
  {
    value: 'Magyar',
    label: 'Magyar',
    group: 'All',
  },
  {
    value: 'Íslenska',
    label: 'Íslenska',
    group: 'All',
  },
  {
    value: 'Bahasa Indonesia',
    label: 'Bahasa Indonesia',
    group: 'All',
  },
  {
    value: 'Gaeilge',
    label: 'Gaeilge',
    group: 'All',
  },
  {
    value: 'Italiano',
    label: 'Italiano',
    group: 'All',
  },
  {
    value: '日本語',
    label: '日本語',
    group: 'All',
  },
  {
    value: 'Basa Jawa',
    label: 'Basa Jawa',
    group: 'All',
  },
  {
    value: 'ಕನ್ನಡ',
    label: 'ಕನ್ನಡ',
    group: 'All',
  },
  {
    value: 'Қазақ тілі',
    label: 'Қазақ тілі',
    group: 'All',
  },
  {
    value: 'ភាសាខ្មែរ',
    label: 'ភាសាខ្មែរ',
    group: 'All',
  },
  {
    value: '한국어',
    label: '한국어',
    group: 'All',
  },
  {
    value: 'Kurdî',
    label: 'Kurdî',
    group: 'All',
  },
  {
    value: 'Кыр',
    label: 'Кыр',
    group: 'All',
  },
  {
    value: 'ພາສາລາວ',
    label: 'ພາສາລາວ',
    group: 'All',
  },
  {
    value: 'Latīna',
    label: 'Latīna',
    group: 'All',
  },
  {
    value: 'Latviešu valoda',
    label: 'Latviešu valoda',
    group: 'All',
  },
  {
    value: 'Lietuvių kalba',
    label: 'Lietuvių kalba',
    group: 'All',
  },
  {
    value: 'Lëtzebuergesch',
    label: 'Lëtzebuergesch',
    group: 'All',
  },
  {
    value: 'Македонски јазик',
    label: 'Македонски јазик',
    group: 'All',
  },
  {
    value: 'Malagasy',
    label: 'Malagasy',
    group: 'All',
  },
  {
    value: 'Bahasa Melayu',
    label: 'Bahasa Melayu',
    group: 'All',
  },
  {
    value: 'മലയാളം',
    label: 'മലയാളം',
    group: 'All',
  },
  {
    value: 'Bahasa Malaysia',
    label: 'Bahasa Malaysia',
    group: 'All',
  },
  {
    value: 'Malti',
    label: 'Malti',
    group: 'All',
  },
  {
    value: 'Māori',
    label: 'Māori',
    group: 'All',
  },
  {
    value: 'मराठी',
    label: 'मराठी',
    group: 'All',
  },
  {
    value: 'Монгол хэл',
    label: 'Монгол хэл',
    group: 'All',
  },
  {
    value: 'नेपाली',
    label: 'नेपाली',
    group: 'All',
  },
  {
    value: 'Norsk',
    label: 'Norsk',
    group: 'All',
  },
  {
    value: 'ଓଡ଼ିଆ',
    label: 'ଓଡ଼ିଆ',
    group: 'All',
  },
  {
    value: 'پښتو',
    label: 'پښتو',
    group: 'All',
  },
  {
    value: 'فارسی',
    label: 'فارسی',
    group: 'All',
  },
  {
    value: 'Polski',
    label: 'Polski',
    group: 'All',
  },
  {
    value: 'Português',
    label: 'Português',
    group: 'All',
  },
  {
    value: 'ਪੰਜਾਬੀ',
    label: 'ਪੰਜਾਬੀ',
    group: 'All',
  },
  {
    value: 'Română',
    label: 'Română',
    group: 'All',
  },
  {
    value: 'Русский',
    label: 'Русский',
    group: 'All',
  },
  {
    value: 'Српски језик',
    label: 'Српски језик',
    group: 'All',
  },
  {
    value: 'Sesotho',
    label: 'Sesotho',
    group: 'All',
  },
  {
    value: 'සිංහල',
    label: 'සිංහල',
    group: 'All',
  },
  {
    value: 'Slovenčina',
    label: 'Slovenčina',
    group: 'All',
  },
  {
    value: 'Slovenščina',
    label: 'Slovenščina',
    group: 'All',
  },
  {
    value: 'Af Soomaali',
    label: 'Af Soomaali',
    group: 'All',
  },
  {
    value: 'Español',
    label: 'Español',
    group: 'All',
  },
  {
    value: 'Basa Sunda',
    label: 'Basa Sunda',
    group: 'All',
  },
  {
    value: 'Kiswahili',
    label: 'Kiswahili',
    group: 'All',
  },
  {
    value: 'Svenska',
    label: 'Svenska',
    group: 'All',
  },
  {
    value: 'Tagalog',
    label: 'Tagalog',
    group: 'All',
  },
  {
    value: 'Тоҷикӣ',
    label: 'Тоҷикӣ',
    group: 'All',
  },
  {
    value: 'தமிழ்',
    label: 'தமிழ்',
    group: 'All',
  },
  {
    value: 'Tatarça',
    label: 'Tatarça',
    group: 'All',
  },
  {
    value: 'తెలుగు',
    label: 'తెలుగు',
    group: 'All',
  },
  {
    value: 'ไทย',
    label: 'ไทย',
    group: 'All',
  },
  {
    value: 'Türkçe',
    label: 'Türkçe',
    group: 'All',
  },
  {
    value: 'Українська',
    label: 'Українська',
    group: 'All',
  },
  {
    value: 'اردو',
    label: 'اردو',
    group: 'All',
  },
  {
    value: 'ئۇيغۇرچە',
    label: 'ئۇيغۇرچە',
    group: 'All',
  },
  {
    value: 'O\'zbek tili',
    label: 'O\'zbek tili',
    group: 'All',
  },
  {
    value: 'Việt ngữ',
    label: 'Việt ngữ',
    group: 'All',
  },
  {
    value: 'Cymraeg',
    label: 'Cymraeg',
    group: 'All',
  },
  {
    value: 'isiXhosa',
    label: 'isiXhosa',
    group: 'All',
  },
  {
    value: 'اليديشية',
    label: 'اليديشية',
    group: 'All',
  },
  {
    value: 'Yorùbá',
    label: 'Yorùbá',
    group: 'All',
  },
  {
    value: 'isiZulu',
    label: 'isiZulu',
    group: 'All',
  },
  {
    value: 'አማርኛ',
    label: 'አማርኛ',
    group: 'All',
  },
  {
    value: 'অসমীয়া',
    label: 'অসমীয়া',
    group: 'All',
  },
  {
    value: 'Binisaya',
    label: 'Binisaya',
    group: 'All',
  },
  {
    value: 'ᏣᎳᎩ',
    label: 'ᏣᎳᎩ',
    group: 'All',
  },
  {
    value: 'རྫོང་ཁ།',
    label: 'རྫོང་ཁ།',
    group: 'All',
  },
  {
    value: 'ᐃᓄᒃᑎᑐᑦ',
    label: 'ᐃᓄᒃᑎᑐᑦ',
    group: 'All',
  },
  {
    value: 'संस्कृतम्',
    label: 'संस्कृतम्',
    group: 'All',
  },
  {
    value: 'السريانية',
    label: 'السريانية',
    group: 'All',
  },
  {
    value: 'བོད་སྐད།',
    label: 'བོད་སྐད།',
    group: 'All',
  },
  {
    value: 'ትግርኛ',
    label: 'ትግርኛ',
    group: 'All',
  },
];