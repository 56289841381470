import axios, { AxiosResponse } from 'axios';
import { cookie } from '~/utils';

export default async function convertFile(file: File, id: string) {
  const endpoint =
    import.meta.env.VITE_CONVERT_API_URL || 'http://localhost:8001/e/v1/convert-file'; // https://api.ai-pro.org/e/v1/convert-file

  const formData = new FormData();
  const aiwp_logged_in = cookie.getValue('aiwp_logged_in') || '';

  formData.append('aiwp_logged_in', aiwp_logged_in);
  formData.append('file', file);
  formData.append('target_format', 'png');
  formData.append('file_id', id);


  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const response: AxiosResponse = await axios.post(endpoint, formData, { headers });
  return response.data;
}
