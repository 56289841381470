import { cn } from '~/utils';

export default function StopGeneratingIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="currentColor"
      className="h-2 w-2"
      height="16"
      width="16"
      role="img"
      aria-label="Stop generating icon"
    >
      <path
        role="img"
        aria-label="Stop generating icon"
        d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"
        strokeWidth="0"
      ></path>
    </svg>
  );
}
