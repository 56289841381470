import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import store from '~/store';
import { Button } from '~/components/ui';
import { View } from 'lucide-react';

/**
 * If closePopover is defined, this button will be added.
 * Currently, this is only added in Save As Presets Dialog.
 */

export default function ViewSavedPresetsButton() {
  const [presets] = useRecoilState(store.presets);
  const [menuOpen, setMenuOpen] = useRecoilState(store.isEndpointMenuOpen);
  const [showPopover, setShowPopover] = useRecoilState(store.showPopover);
  const [show, setShow] = useState<boolean | undefined>(undefined);

  const buttonRef = useRef(null);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setMenuOpen(true);
  };

  useEffect(() => {
    if (buttonRef.current) {
      if ((buttonRef.current as Element).closest('.endpointOptionsPopover-container')) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }, []);

  if (show || show == undefined) {
    return (
      <div className="w-full">
        <Button
          type="button"
          className={`h-auto justify-start bg-transparent px-0 py-1 text-xs font-normal text-black transition-opacity hover:bg-slate-200 hover:text-black focus:ring-0 dark:bg-transparent dark:text-white dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-offset-0 ${
            presets.length > 0 ? 'opacity-100' : 'pointer-events-none opacity-0'
          }`}
          onClick={handleClick}
          ref={buttonRef}
          aria-label="View Saved Presets"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mr-1 w-[14px]"
            role="img"
            aria-label="View Saved Presets"
          >
            <path
              role="img"
              aria-label="View Saved Presets"
              d="M5 12s2.545-5 7-5c4.454 0 7 5 7 5s-2.546 5-7 5c-4.455 0-7-5-7-5z"
            ></path>
            <path
              role="img"
              aria-label="View Saved Presets"
              d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
            ></path>
            <path
              role="img"
              aria-label="View Saved Presets"
              d="M21 17v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2"
            ></path>
            <path
              role="img"
              aria-label="View Saved Presets"
              d="M21 7V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2"
            ></path>
          </svg>
          View Saved Presets
        </Button>
      </div>
    );
  }

  return null;
}
