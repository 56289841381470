import React from 'react';
import { toast } from 'react-toastify';
import { TFilePreview } from '~/common';

interface FileError {
  message: string;
  code: string;
}
export interface FileRejection {
  file: File;
  errors: FileError[];
}

export const fileLimit = 5; // MB
export const maxFileSize = 5; // MB

export const DropzoneOptions = {
  accept: {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    'image/gif': ['.gif'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'text/plain': ['.txt'],
  },
  noClick: true,
  noKeyboard: true,
  multiple: true,
  // maxFiles: fileLimit,
  maxSize: maxFileSize * 1024 * 1024,
};

export function validator(file: File, filePreview: TFilePreview[]) {
  const isUploaded = filePreview.some((image: TFilePreview) => image.file_name === file.name);
  if (isUploaded) {
    return {
      code: 'file-already-uploaded',
      message: `File already uploaded.`,
    };
  }
  return null;
}

export const clearFile = () => {
  const uploadImage = document.getElementById('upload-file') as HTMLInputElement | null;
  if (uploadImage) {
    uploadImage.value = '';
  }
};

export function validateFile(
  files: File[],
  fileRejections: FileRejection[],
  filePreview: TFilePreview[],
) {
  let isValid = true;
  let err: string[] = [];
  const TooManyFiles = `You can only upload up to ${fileLimit} file${fileLimit > 1 ? 's' : ''}`;
  if (fileRejections.length > 0) {
    // toast.dismiss();
    fileRejections.forEach((fileRejection) => {
      if (fileRejection.errors[0].code.includes('file-invalid-type')) {
        if (!err.includes('File type not supported.')) {
          err.push('File type not supported.');
        }
      } else if (fileRejection.errors[0].code.includes('file-too-large')) {
        if (!err.includes(`File size should be less than ${maxFileSize} MB.`)) {
          err.push(`File size should be less than ${maxFileSize} MB.`);
        }
      } else if (fileRejection.errors[0].code.includes('too-many-files')) {
        if (!err.includes(TooManyFiles)) {
          err.push(TooManyFiles);
        }
      } else {
        if (!err.includes(fileRejection.errors[0].message)) {
          err.push(fileRejection.errors[0].message);
        }
      }
    });
  }

  if (filePreview.length + files.length > fileLimit) {
    if (!err.includes(TooManyFiles)) {
      err.push(TooManyFiles);
      isValid = false;
    }
  }

  if (err.length > 0) {
    const e = React.createElement('div', {
      dangerouslySetInnerHTML: { __html: err.join('<br/>') },
    });
    toast.dismiss();
    toast.error(e);
  }
  return isValid;
}

export function onError(error: any) {
  console.error(error);
}
