import { useRecoilValue } from 'recoil';
import { OpenAISettings, BingAISettings, AnthropicSettings, OpensourceSettings, GeminiSettings, ClaudeSettings, DallESettings } from './Settings';
import { GoogleSettings, PluginsSettings } from './Settings/MultiView';
import type { TSettingsProps, TModelSelectProps, TBaseSettingsProps, TModels } from '~/common';
import { cn } from '~/utils';
import store from '~/store';

const optionComponents: { [key: string]: React.FC<TModelSelectProps> } = {
  openAI: OpenAISettings,
  azureOpenAI: OpenAISettings,
  bingAI: BingAISettings,
  anthropic: AnthropicSettings,
  Opensource: OpensourceSettings,
  Llama: OpensourceSettings,
  claude: ClaudeSettings,
  gemini: GeminiSettings,
  dallE: DallESettings
};

const multiViewComponents: { [key: string]: React.FC<TBaseSettingsProps & TModels> } = {
  google: GoogleSettings,
  gptPlugins: PluginsSettings,
};

export default function Settings({
  conversation,
  setOption,
  isPreset = false,
  className = '',
}: TSettingsProps) {
  const endpointsConfig = useRecoilValue(store.endpointsConfig);
  if (!conversation?.endpoint) {
    return null;
  }

  const { endpoint } = conversation;
  const models = endpointsConfig?.[endpoint]?.['availableModels'] || [];
  const OptionComponent = optionComponents[endpoint];

  if (OptionComponent) {
    return (
      <div
        className={cn(
          'option-component-container h-auto max-h-[min(45vh,_480px)] overflow-y-auto md:mb-2 md:max-h-[min(45vh,_350px)]',
          className,
        )}
      >
        <OptionComponent
          conversation={conversation}
          setOption={setOption}
          models={models}
          isPreset={isPreset}
        />
      </div>
    );
  }

  const MultiViewComponent = multiViewComponents[endpoint];

  if (!MultiViewComponent) {
    return null;
  }

  return (
    <div
      className={cn(
        'h-auto max-h-[min(50vh,_480px)] overflow-y-auto md:mb-2 md:max-h-[min(50vh,_350px)]',
        className,
      )}
    >
      <MultiViewComponent conversation={conversation} models={models} isPreset={isPreset} />
    </div>
  );
}
