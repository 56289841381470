import React from 'react';

export default function DotsIcon() {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="h-4 w-4 flex-shrink-0 text-gray-500"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Dots icon"
    >
      <circle role="img" aria-label="Dots icon" cx="12" cy="12" r="1" />
      <circle role="img" aria-label="Dots icon" cx="19" cy="12" r="1" />
      <circle role="img" aria-label="Dots icon" cx="5" cy="12" r="1" />
    </svg>
  );
}
