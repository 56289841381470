import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Settings } from 'lucide-react';
import { DropdownMenuRadioItem } from '~/components';
import { getIcon } from '~/components/Endpoints';
import { SetTokenDialog } from '../SetTokenDialog';
import { useLocalize } from '~/hooks';
import { IoIosLock } from 'react-icons/io';
import store from '~/store';
import { cn, alternateName } from '~/utils';
import { subscriptionType } from '../../helper/store';

export default function ModelItem({
  endpoint,
  value,
  activeEndpoint
}: {
  endpoint: string;
  value: string;
  activeEndpoint: string;
}) {
  const [setTokenDialogOpen, setSetTokenDialogOpen] = useState(false);
  const endpointsConfig = useRecoilValue(store.endpointsConfig);

  const icon = getIcon({
    size: 20,
    endpoint,
    error: false,
    className: 'mr-2',
    message: false,
  });

  const isUserProvided = endpointsConfig?.[endpoint]?.userProvide;
  const localize = useLocalize();
  const proState = subscriptionType((state) => state);
  const isSelected = endpoint === activeEndpoint;

  return (
    
    <>
      <DropdownMenuRadioItem
        value={value}
        className={cn(
          'group dark:font-semibold dark:text-gray-100 dark:hover:bg-gray-800',
          isSelected ? 'active bg-gray-50 dark:bg-gray-800' : '',
        )}
        style={{ 
          display: (proState.isEnterprise && (endpoint === 'dallE' || endpoint === 'claude')) ? 'none' : '',
          pointerEvents: isSelected ? 'none' : 'auto', // Disable click events if selected
          cursor: isSelected ? 'default' : 'pointer'  // Change cursor to default if selected
        }}
        // style={{ display: proState.isEnterprise && (endpoint === 'dallE' || endpoint === 'claude') ? 'none' : '' }}
        id={endpoint}
        activeEndpoint={activeEndpoint}
        data-testid={`endpoint-item-${endpoint}`}
      >

        <div
          dangerouslySetInnerHTML={{
            __html:
              endpoint === 'Opensource'
                ? 'More LLMs'
                : endpoint === 'openAI'
                ? 'GPTs'
                : endpoint === 'dallE' 
                ? 'DALL·E' 
                : alternateName[endpoint] || endpoint,
          }}
        />
    
        { (
          (proState.isEnterprise === false) && (
          (proState.isProMax === false && ['Opensource', 'dallE', 'claude'].includes(endpoint)) ||
          (proState.isPro === false && proState.isProMax === false && (endpoint === 'gemini' || (endpoint === 'Llama' && AIPRO_USER.subscription_type !== 'BASIC')))
            )
          ) ? (
            <IoIosLock className='ml-1 mb-1 inline text-[18px] text-gray-500'/>
          ) : null}

        {endpoint === 'gptPlugins' || endpoint === 'Opensource' ? (
          <span className="py-0.25 ml-1 rounded bg-blue-200 px-1 text-[10px] font-semibold text-[#4559A4]">
            Beta
          </span>
        ) : null}

        {(proState.isPro === false && proState.isProMax === false && proState.isEnterprise === false) && (endpoint === 'google' || endpoint === 'gemini') ? (
          <span className="gptpro ml-2 rounded-md bg-green-500 px-1 py-0.5 text-xs text-white">
            PRO
          </span>
        ) : null}

        {(proState.isProMax === false && proState.isEnterprise === false ) && (endpoint === 'dallE' || endpoint === 'claude' || endpoint === 'Opensource') ? (
          <span className="gptpro ml-2 rounded-md bg-green-500 px-1 py-0.5 text-xs text-white">
            PRO MAX
          </span>
        ) : null}

        <div className="flex w-4 flex-1" />
        {isUserProvided ? (
          <button
            className={cn(
              'invisible m-0 mr-1 flex-initial rounded-md p-0 text-xs font-medium text-gray-400 hover:text-gray-700 group-hover:visible dark:font-normal dark:text-gray-400 dark:hover:text-gray-200',
              isSelected ? 'visible text-gray-700 dark:text-gray-200' : '',
            )}
            
            onClick={(e) => {
              e.preventDefault();
              setSetTokenDialogOpen(true);
            }}
          >
            <Settings className="mr-1 inline-block w-[16px] items-center stroke-1" />
            {localize('com_endpoint_config_token')}
          </button>
        ) : null}
      </DropdownMenuRadioItem>
      <SetTokenDialog
        open={setTokenDialogOpen}
        onOpenChange={setSetTokenDialogOpen}
        endpoint={endpoint}
      />
    </>
  );
}
