const stringToBoolean = (stringValue) => {
  switch (stringValue?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case '':
    case null:
    case undefined:
      return false;
    default:
      return stringValue;
  }
};
export default stringToBoolean;
