import { useEffect } from 'react';

const OnMouseLeave = (targetRef: React.RefObject<HTMLElement>, callback: () => void) => {
  useEffect(() => {
    const onMouseLeave = () => {
      callback();
    };

    targetRef.current?.addEventListener('mouseleave', onMouseLeave);

    return () => {
      targetRef.current?.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [targetRef, callback]);

  return null;
};

export default OnMouseLeave;
