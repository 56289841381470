import { useSetRecoilState } from 'recoil';
import store from '~/store';

export function useUpdateConversation() {
  const setConversation = useSetRecoilState(store.conversation);

  const updateConversation = (model) => {
    setConversation(prevConversation => ({
      ...prevConversation,
      model: model.model,
      endpoint: model.endpoint,
    }));
  };

  return updateConversation;
}