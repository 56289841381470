/* eslint-disable react-hooks/exhaustive-deps */
import { Settings, Trash2 } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { useDeletePresetMutation, useCreatePresetMutation } from 'librechat-data-provider';
import { getIcon, EditPresetDialog } from '~/components/Endpoints';
import EndpointItems from './EndpointItems';
import PresetItems from './PresetItems';
import FileUpload from './FileUpload';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Dialog,
  DialogTrigger,
} from '~/components/ui/';
import DialogTemplate from '~/components/ui/DialogTemplate';
import { cn, cleanupPreset, getDefaultConversation, cookie } from '~/utils';
import global from '~/utils/global';
import { useLocalize, useSetOptions } from '~/hooks';
import store from '~/store';
import { setCookieValue } from '~/components/helper/global';
import { activeEndpointState } from '~/store/endpoints';

import { usePopup, usePricingPopup, subscriptionType } from '~/components/helper/store';
export default function NewConversationMenu() {
  const [menuOpen, setMenuOpen] = useRecoilState(store.isEndpointMenuOpen);
  const [showPresets, setShowPresets] = useState(true);
  const [showEndpoints, setShowEndpoints] = useState(true);
  const [presetModelVisible, setPresetModelVisible] = useState(false);
  const [preset, setPreset] = useState(false);
  const [conversation, setConversation] = useRecoilState(store.conversation) || {};
  const [messages, setMessages] = useRecoilState(store.messages);
  const availableEndpoints = useRecoilValue(store.availableEndpoints);
  const [activeEndpoint] = useRecoilState(activeEndpointState);
  const setActiveEndpoint = useSetRecoilState(activeEndpointState);
  const endpointsConfig = useRecoilValue(store.endpointsConfig);
  const [presets, setPresets] = useRecoilState(store.presets);
  const modularEndpoints = new Set(['gptPlugins', 'anthropic', 'google', 'openAI', 'Opensource', 'Gemini', 'claude', 'dallE']);
  const [showPopover, setShowPopover] = useRecoilState(store.showPopover);
  const [showOpenAiModels, setShowOpenAiModels] = useRecoilState(store.showOpenAiModels);

  const upgradeUrl = import.meta.env.VITE_UPGRADE_URL || 'https://start.ai-pro.org/upgrade';

  const { endpoint, conversationId } = conversation;
  const { newConversation } = store.useConversation();
  const deletePresetsMutation = useDeletePresetMutation();
  const createPresetMutation = useCreatePresetMutation();
  const sState = usePopup((state) => state);
  const pState = usePricingPopup((state) => state);
  const [icon, setIcon] = useState(null);
  
  const proState = subscriptionType((state) => state);
  // console.log('Current activeEndpoint:', activeEndpoint);
  const user_secret_id = import.meta.env.USER_SECRET_ID
    ? import.meta.env.USER_SECRET_ID
    : 'chatpro_KJLF4XgSL8wjlGm';
  const user = cookie.getValue(user_secret_id);

  const importPreset = (jsonData) => {
    createPresetMutation.mutate(
      { ...jsonData },
      {
        onSuccess: (data) => {
          setPresets(data);
        },
        onError: (error) => {
          console.error('Error uploading the preset:', error);
        },
      },
    );
  };

  const onFileSelected = (jsonData) => {
    const jsonPreset = { ...cleanupPreset({ preset: jsonData, endpointsConfig }), presetId: null };
    importPreset(jsonPreset);
  };

  useEffect(() => {
    const isInvalidConversation = !availableEndpoints.find((e) => e === endpoint);
    if (conversationId == 'new' && isInvalidConversation) {
      newConversation();
    }
  }, [availableEndpoints]);
  // save selected model to localStorage
  useEffect(() => {
    if (endpoint) {
      const lastSelectedModel = JSON.parse(localStorage.getItem('lastSelectedModel')) || {};
      localStorage.setItem(
        'lastSelectedModel',
        JSON.stringify({ ...lastSelectedModel, [endpoint]: conversation.model }),
      );
      localStorage.setItem('lastConversationSetup', JSON.stringify(conversation));
    }

    if (endpoint === 'bingAI') {
      const lastBingSettings = JSON.parse(localStorage.getItem('lastBingSettings')) || {};
      const { jailbreak, toneStyle } = conversation;
      localStorage.setItem(
        'lastBingSettings',
        JSON.stringify({ ...lastBingSettings, jailbreak, toneStyle }),
      );
    }
  }, [conversation]);

  const isDisable = (newEndpoint) => {
    return (
      (proState.isEnterprise === false) &&
      (
        (proState.isProMax === false && ['Opensource', 'dallE', 'claude'].includes(newEndpoint)) ||
        (proState.isPro === false && proState.isProMax === false && 
          (newEndpoint === 'gemini' || (newEndpoint === 'Llama' && AIPRO_USER.subscription_type !== 'BASIC')))
      )
    );
  };

  const onSelectEndpoint = (newEndpoint) => {
    if (!isDisable(newEndpoint)) {
      setActiveEndpoint(newEndpoint);
    }

    setMenuOpen(false);

    if (!newEndpoint) {
      return;
    } else if ( // GUEST-UNPAID
      (newEndpoint == 'dallE' || newEndpoint == 'claude'  || newEndpoint == 'Llama' || newEndpoint == 'Opensource') &&
      (AIPRO_USER.subscription_type == null)
    ) {
      if (
        cookie.getValue('flow') === 'chatapp' &&
        cookie.getValue('n_flow') === 'chatapp' &&
        !cookie.getValue('user_plan')
      ) {
        if (window.mixpanel) {
          window.mixpanel.track("endpoint-option-chatpro-chatapp", {
            "mixPanelTrack": newEndpoint,
            "mixPanelClickLocation": 'endpoint-option-chatpro-chatapp',
          });
        }
      } else {
        sState.setMixPanelTrack(newEndpoint);
        sState.setMixPanelClickLocation('endpoint-option-chatpro-modal');
        setCookieValue('mixPanelTrack', newEndpoint, '.ai-pro.org');
        setCookieValue('mixPanelClickLocation', 'endpoint-option-chatpro-modal', '.ai-pro.org');  
      }
      global.showPricing(() => {
        pState.setShowPricingPopup(true);
      });
    } else if ( // BASIC
      (newEndpoint == 'Opensource') &&
      (AIPRO_USER.subscription_type == 'BASIC')
    ) {
      if (window.mixpanel) {
        window.mixpanel.track("endpoint-option-chatpro-redirect", {
          "mixPanelTrack": newEndpoint,
          "mixPanelClickLocation": 'endpoint-option-chatpro-redirect',
        });
      }
      const ppg = cookie.getValue('ppg') || 59;
      window.open(`${upgradeUrl}/?ppg=${ppg}`, '_blank');
    } else if ( // PRO
      (newEndpoint == 'dallE' || newEndpoint == 'claude' || newEndpoint == 'Opensource') &&
      (AIPRO_USER.subscription_type == 'PRO')
    ) {
      if (window.mixpanel) {
        window.mixpanel.track("endpoint-option-chatpro-redirect", {
          "mixPanelTrack": newEndpoint,
          "mixPanelClickLocation": 'endpoint-option-chatpro-redirect',
        });
      }
      const ppg = cookie.getValue('ppg') || 59;
      window.open(`${upgradeUrl}/?ppg=${ppg}`, '_blank');
    } else if ( // ENTERPRISE
      (newEndpoint == 'dallE' || newEndpoint == 'claude') &&
      (!proState.isProMax)
    ) {
      if (window.mixpanel) {
        window.mixpanel.track("endpoint-option-chatpro-redirect", {
          "mixPanelTrack": newEndpoint,
          "mixPanelClickLocation": 'endpoint-option-chatpro-redirect',
        });
      }
      const ppg = cookie.getValue('ppg') || 59;
      window.open(`${upgradeUrl}/?ppg=${ppg}`, '_blank');
    } else if ( // UNPAID BUT REGISTERED
      (newEndpoint == 'google' || newEndpoint == 'gemini') &&
      typeof AIPRO_USER !== 'undefined' &&
      (!AIPRO_USER.subscription_type ||
        AIPRO_USER.subscription_type.toString().toLowerCase() === 'basic')
    ) {
      if (
        cookie.getValue('flow') === 'chatapp' &&
        cookie.getValue('n_flow') === 'chatapp' &&
        !cookie.getValue('user_plan')
      ) {
        if (window.mixpanel) {
          window.mixpanel.track("endpoint-option-chatpro-chatapp", {
            "mixPanelTrack": newEndpoint,
            "mixPanelClickLocation": 'endpoint-option-chatpro-chatapp',
          });
        }
      } else {
        sState.setMixPanelTrack(newEndpoint);
        sState.setMixPanelClickLocation('endpoint-option-chatpro-modal');
        setCookieValue('mixPanelTrack', newEndpoint, '.ai-pro.org');
        setCookieValue('mixPanelClickLocation', 'endpoint-option-chatpro-modal', '.ai-pro.org');  
      }
      global.showPricing(() => {
        pState.setShowPricingPopup(true);
      });
    } else {
      newConversation({}, { endpoint: newEndpoint });
    }
  };

  // set the current model
  const isModular = modularEndpoints.has(endpoint);
  const onSelectPreset = (newPreset) => {
    setMenuOpen(false);

    cookie.setValue('mixPanelTrack', newPreset.model);
    cookie.setValue('mixPanelClickLocation', 'Home');
    sState.setMixPanelTrack(newPreset.model);
    sState.setMixPanelClickLocation('Home');

    if (!newPreset) {
      return;
    }

    if (
      isModular &&
      modularEndpoints.has(newPreset?.endpoint) &&
      endpoint === newPreset?.endpoint
    ) {
      const currentConvo = getDefaultConversation({
        conversation,
        endpointsConfig,
        preset: newPreset,
      });

      setConversation(currentConvo);
      setMessages(messages);
      return;
    }

    newConversation({}, newPreset);
  };

  const onChangePreset = (preset) => {
    setPresetModelVisible(true);
    setPreset(preset);
  };

  const clearAllPresets = () => {
    deletePresetsMutation.mutate({ arg: {} });
  };

  const onDeletePreset = (preset) => {
    if (
      preset &&
      isModular &&
      modularEndpoints.has(preset?.endpoint) &&
      endpoint === preset?.endpoint
    ) {
      const currentConvo = getDefaultConversation({
        conversation,
        endpointsConfig,
        preset: null,
      });

      setConversation(currentConvo);
      setMessages(messages);
    }

    deletePresetsMutation.mutate({ arg: preset });
  };

  useEffect(() => {
    setIcon(
      getIcon({
        size: 32,
        ...conversation,
        isCreatedByUser: false,
        error: false,
        button: true,
      }),
    );
  }, [conversation]);

  const localize = useLocalize();

  useEffect(() => {
    if (menuOpen) {
      setShowPopover(false);
      setShowOpenAiModels(false);
    }
  }, [menuOpen]);

  return (
    <Dialog className="z-[100]">
      <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            id="new-conversation-menu"
            data-testid="new-conversation-menu"
            variant="outline"
            className={
              'new-topic-btn data-[state=open]:bg-slate-10 group relative mb-[-12px] ml-[-4px] mt-[-8px] items-center rounded-md border-0 bg-slate-200/50 px-1 py-0 hover:bg-slate-200 hover:px-2 focus:ring-0 focus:ring-offset-0 data-[state=open]:bg-slate-200 data-[state=open]:px-2 dark:bg-slate-800/30 dark:hover:bg-slate-800 dark:data-[state=open]:bg-slate-800'
            }
            aria-label="New Topic"
          >
            {/* {icon} */}
            <Settings className="inline-block w-[32px] items-center stroke-1 text-slate-700 transition-all duration-200 group-hover:rotate-90 group-hover:stroke-[2] group-data-[state=open]:rotate-90 group-data-[state=open]:stroke-[2] dark:text-slate-200" />
            <span className="max-w-0 overflow-hidden whitespace-nowrap px-0 text-slate-700 transition-all group-hover:max-w-[80px] group-hover:px-2 group-data-[state=open]:max-w-[80px] group-data-[state=open]:px-2 dark:text-slate-200">
              {localize('com_endpoint_new_topic')}
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="endpoint-menu z-[100] w-[375px] dark:bg-gray-900 md:w-96"
          onCloseAutoFocus={(event) => event.preventDefault()}
        >
          <DropdownMenuLabel
            className="cursor-pointer dark:text-gray-300"
            onClick={() => setShowEndpoints((prev) => !prev)}
            role="button"
            aria-label={
              showEndpoints
                ? localize('com_endpoint_hide') + ' ' + localize('com_endpoint')
                : localize('com_endpoint_show') + ' ' + localize('com_endpoint')
            }
          >
            {showEndpoints ? localize('com_endpoint_hide') : localize('com_endpoint_show')}{' '}
            {localize('com_endpoint')}
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuRadioGroup
            value={endpoint}
            onValueChange={onSelectEndpoint}
            className="flex flex-col gap-1 overflow-y-auto"
          >
            {showEndpoints &&
              (availableEndpoints.length ? (
                <EndpointItems
                  selectedEndpoint={endpoint}
                  endpoints={availableEndpoints}
                  onSelect={onSelectEndpoint}
                  activeEndpoint={activeEndpoint}
                />
              ) : (
                <DropdownMenuLabel className="dark:text-gray-300">
                  {localize('com_endpoint_not_available')}
                </DropdownMenuLabel>
              ))}
          </DropdownMenuRadioGroup>

          <div className="mt-2 w-full" />

          <DropdownMenuLabel className="flex items-center dark:text-gray-300">
            <span
              className="mr-auto cursor-pointer "
              onClick={() => setShowPresets((prev) => !prev)}
              role="button"
              aria-label={
                showPresets
                  ? localize('com_endpoint_hide') + ' ' + localize('com_endpoint_examples')
                  : localize('com_endpoint_show') + ' ' + localize('com_endpoint_examples')
              }
            >
              {showPresets ? localize('com_endpoint_hide') : localize('com_endpoint_show')}{' '}
              {localize('com_endpoint_examples')}
            </span>
            {user && (
              <>
                <FileUpload onFileSelected={onFileSelected} />
                <Dialog>
                  <DialogTrigger asChild>
                    <label
                      htmlFor="file-upload"
                      className="mr-1 flex h-auto min-h-[32px] cursor-pointer  items-center rounded bg-transparent px-2 py-1 text-xs font-medium text-gray-600 transition-colors hover:bg-slate-200 hover:text-red-700 dark:bg-transparent dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-green-500"
                      role="button"
                      aria-label="Clear all presets"
                    >
                      {/* <Button
                  type="button"
                  className="h-auto bg-transparent px-2 py-1 text-xs font-medium font-normal text-red-700 hover:bg-slate-200 hover:text-red-700 dark:bg-transparent dark:text-red-400 dark:hover:bg-gray-800 dark:hover:text-red-400"
                > */}
                      <Trash2
                        className="mr-1 flex w-[22px] items-center stroke-1"
                        role="img"
                        aria-label="Clear all presets"
                      />
                      {localize('com_endpoint_clear_all')}
                      {/* </Button> */}
                    </label>
                  </DialogTrigger>
                  <DialogTemplate
                    title="Clear presets"
                    description="Are you sure you want to clear all presets? This is irreversible."
                    selection={{
                      selectHandler: clearAllPresets,
                      selectClasses: 'bg-red-600 hover:bg-red-700 dark:hover:bg-red-800 text-white',
                      selectText: 'Clear',
                    }}
                  />
                </Dialog>
              </>
            )}
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuRadioGroup
            onValueChange={onSelectPreset}
            className={cn(
              'overflow-y-auto overflow-x-hidden',
              showEndpoints ? 'max-h-[210px]' : 'max-h-[315px]',
            )}
          >
            {showPresets &&
              (presets.length ? (
                <PresetItems
                  presets={presets}
                  onSelect={onSelectPreset}
                  onChangePreset={onChangePreset}
                  onDeletePreset={onDeletePreset}
                />
              ) : (
                <DropdownMenuLabel
                  className="dark:text-gray-300"
                  role="contentinfo"
                  aria-label={localize('com_endpoint_no_presets')}
                >
                  {localize('com_endpoint_no_presets')}
                </DropdownMenuLabel>
              ))}
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <EditPresetDialog
        open={presetModelVisible}
        onOpenChange={setPresetModelVisible}
        preset={preset}
      />
    </Dialog>
  );
}
