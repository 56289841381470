export default function Panel({ open = false }) {
  const openPanel = (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="h-4 w-4"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Open panel icon"
    >
      <rect
        role="img"
        aria-label="Open panel icon"
        x="3"
        y="3"
        width="18"
        height="18"
        rx="2"
        ry="2"
      ></rect>
      <line x1="9" y1="3" x2="9" y2="21"></line>
    </svg>
  );

  const closePanel = (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="h-4 w-4"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Close panel icon"
    >
      <rect
        role="img"
        aria-label="Close panel icon"
        x="3"
        y="3"
        width="18"
        height="18"
        rx="2"
        ry="2"
      ></rect>
      <line role="img" aria-label="Close panel icon" x1="9" y1="3" x2="9" y2="21"></line>
    </svg>
  );

  if (open) {
    return openPanel;
  } else {
    return closePanel;
  }
}
