import { useMemo } from 'react';
import { getModelName } from '../helper/modelName.js';

export function useAppModel() {
  const openai_gpt4_model = import.meta.env.VITE_OPENAI_GPT4_MODEL || 'gpt-4-1106-preview';
  const openai_gpt3_model = import.meta.env.VITE_OPENAI_GPT3_MODEL || 'gpt-3.5-turbo-0125';
  const claude_model = import.meta.env.VITE_CLAUDE_MODEL || 'claude-3-5-sonnet-20240620';
  const dalle_model = import.meta.env.VITE_DALLE_MODEL || 'dall-e-3';
  const userPlan = window.AIPRO_USER?.subscription_type || [];
 
  // console.log('User Plan:', userPlan);
  const models = useMemo(() => {

    const modelsList = [
      {
      model: openai_gpt3_model,
      endpoint: 'openAI',
      title: getModelName(openai_gpt3_model),
      desciption: {
        title: getModelName(openai_gpt3_model),
        token: '4,096',
        content: `${getModelName(openai_gpt3_model)} is a fast model from OpenAI, it is great for
                    everday tasks. It offers swift and efficient solutions, making it perfect for
                    various applications. This model is powered by OpenAI.`,
      },
      isDisabled: false,
      isNew: false,
      isBeta: false,
      isDefault: false,
      isVisible: true,
      isBasic: true,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
      maxInputCharacters: 12000,
    },
    {
      model: 'gpt-4o',
      endpoint: 'openAI',
      title: getModelName('gpt-4o'),
      desciption: {
        title: getModelName('gpt-4o'),
        token: '128,000',
        content: `GPT-4o is OpenAI's latest advanced multimodal model that's 2x faster than GPT-4,
                    but with the intelligence to match. Great for long, complex conversations. This
                    model is powered by OpenAI.`,
      },
      isDisabled: false,
      isNew: false,
      isBeta: false,
      isDefault: false,
      isVisible: true,
      isBasic: true,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
      maxInputCharacters: 96000,
    },
    {
      model: 'gpt-4o-mini',
      endpoint: 'openAI',
      title: getModelName('gpt-4o-mini'),
      desciption: {
        title: getModelName('gpt-4o-mini'),
        token: '128,000',
        content: `GPT-4o mini is an optimized AI model developed by OpenAI. It's designed to handle a wide range of tasks with enhanced capabilities in reasoning across various input types. This model is a variation of GPT-4o focused on speed and efficiency, making powerful AI more accessible for various applications.`,
      },
      isDisabled: false,
      isNew: true,
      isBeta: false,
      isDefault: true,
      isVisible: true,
      isBasic: true,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
      maxInputCharacters: 96000,
    },
    {
      model: openai_gpt4_model,
      endpoint: 'openAI',
      title: getModelName(openai_gpt4_model),
      desciption: {
        title: getModelName(openai_gpt4_model),
        token: '8,192',
        content: `With an even larger parameter count, further enhances language capabilities,
                    surpassing its predecessor in comprehensiveness, context understanding, and
                    generating more contextually relevant and coherent responses. This model is
                    powered by OpenAI.`,
      },
      isDisabled: ['PROMAX'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: false,
      isVisible: true,
      isBasic: false,
      isPro: false,
      isPromax: true,
      isEnterprise: false,
      maxInputCharacters: 6000,
    },
    {
      model: 'gemini-1.5-flash',
      endpoint: 'gemini',
      title: getModelName('gemini-1.5-flash'),
      desciption: {
        title: getModelName('gemini-1.5-flash'),
        token: '1,000,000',
        content: 'Gemini 1.5 Flash combines lightning-fast processing with pinpoint accuracy, revolutionizing real-time interactions for chatbots and virtual assistants.',
      },
      isDisabled: ['PRO', 'PROMAX', 'ENTERPRISE'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: true,
      isVisible: true,
      isBasic: false,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
      maxInputCharacters: 100000,
    },
    {
      model: 'gemini-1.5-pro',
      endpoint: 'gemini',
      title: getModelName('gemini-1.5-pro'),
      desciption: {
        title: getModelName('gemini-1.5-pro'),
        token: '1,000,000',
        content: 'Gemini 1.5 Pro is a cutting-edge language model that excels in understanding and generating human-like text with remarkable accuracy. Its advanced capabilities enhance productivity and drive innovation across various industries.',
      },
      isDisabled: ['PRO', 'PROMAX', 'ENTERPRISE'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: false,
      isVisible: true,
      isBasic: false,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
      maxInputCharacters: 100000,
    },
    {
      model: 'meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo',
      endpoint: 'Llama',
      title: getModelName('meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo'),
      desciption: {
        title: getModelName('meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo'),
        token: '128,000',
        content: `Llama 3.1 8b offers exceptional performance and efficiency in a compact parameter model, making it ideal for resource-constrained environments while still delivering high-quality results across a wide range of language tasks. This model is powered by Meta.`,
      },
      isDisabled: ['BASIC', 'PRO', 'PROMAX', 'ENTERPRISE'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: true,
      isVisible: true,
      isBasic: true,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
      maxInputCharacters: 96000,
    },
    {
      model: 'meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo',
      endpoint: 'Llama',
      title: getModelName('meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo'),
      desciption: {
        title: getModelName('meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo'),
        token: '128,000',
        content: `The Meta Llama 3.1 70B Instruct Turbo features advanced AI technology that offers unparalleled precision and efficiency in data analysis, making it ideal for complex research projects and deep learning applications. This model is powered by Meta.`,
      },
      isDisabled: ['PRO', 'PROMAX', 'ENTERPRISE'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: false,
      isVisible: true,
      isBasic: false,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
      maxInputCharacters: 96000,
    },
    {
      tab: 'claude',
      model: claude_model,
      endpoint: 'claude',
      title: getModelName('claude'),
      desciption: {
        title: getModelName('claude'),
        token: '200,000',
        content:
          'Claude 3.5 Sonnet, the latest innovation by Anthropic, offers unparalleled natural language understanding and generation, ensuring precise, context-aware interactions that elevate your AI-driven experiences.',
      },
      isDisabled: ['PROMAX'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: true,
      isVisible: true,
      isBasic: false,
      isPro: false,
      isPromax: true,
      isEnterprise: false,
      maxInputCharacters: 100000,
    },
    {
      model: dalle_model,
      endpoint: 'dallE',
      title: 'DALL·E',
      desciption: {
        title: 'DALL·E',
        token: undefined,
        content: `DALL·E 3 understands significantly more nuance and detail than our previous systems,
                  allowing you to easily translate your ideas into exceptionally accurate images.`,
      },
      isDisabled: ['PROMAX'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: true,
      isVisible: true,
      isBasic: false,
      isPro: false,
      isPromax: true,
      isEnterprise: false,
      maxInputCharacters: 4000,
    },
    {
      model: 'google/gemma-2-9b-it',
      endpoint: 'Opensource',
      title: getModelName('google/gemma-2-9b-it'),
      desciption: {
        title: getModelName('google/gemma-2-9b-it'),
        token: '8,200',
        content: `Gemma-2 Instruct (9B) is a compact, efficient language model that excels at instruction-following tasks, offering strong performance for text generation and analysis in resource-limited environments. This model is powered by Google.`,
      },
      isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: true,
      isDefault: true,
      isVisible: true,
      isBasic: false,
      isPro: false,
      isPromax: true,
      isEnterprise: true,
      maxInputCharacters: 6000,
    },
    {
        model: 'mistralai/Mistral-7B-Instruct-v0.3',
        endpoint: 'Opensource',
        title: getModelName('mistralai/Mistral-7B-Instruct-v0.3'),
        desciption: {
          title: getModelName('mistralai/Mistral-7B-Instruct-v0.3'),
          token: '32,768',
          content: `Mistral 7B Instruct v0.3 delivers top-tier language processing capabilities in a compact package, outperforming many larger models while remaining accessible for deployment on consumer hardware. This model is powered by Mistral AI.`,
        },
        isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
        isNew: false,
        isBeta: true,
        isDefault: false,
        isVisible: true,
        isBasic: false,
        isPro: false,
        isPromax: true,
        isEnterprise: true,
        maxInputCharacters: 24000,
    },
    {
        model: 'deepseek-ai/deepseek-llm-67b-chat',
        endpoint: 'Opensource',
        title: getModelName('deepseek-ai/deepseek-llm-67b-chat'),
        desciption: {
          title: getModelName('deepseek-ai/deepseek-llm-67b-chat'),
          token: '4,086',
          content: `DeepSeek LLM Chat (67B) offers superior performance in complex reasoning, creative writing, and code generation, making it ideal for advanced AI applications and software development. This model is powered by DeepSeek.`,
        },
        isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
        isNew: false,
        isBeta: true,
        isDefault: false,
        isVisible: true,
        isBasic: false,
        isPro: false,
        isPromax: true,
        isEnterprise: true,
        maxInputCharacters: 3000,
    },
    {
        model: 'databricks/dbrx-instruct',
        endpoint: 'Opensource',
        title: getModelName('databricks/dbrx-instruct'),
        desciption: {
          title: getModelName('databricks/dbrx-instruct'),
          token: '32,000',
          content: `DBRX Instruct excels in following complex instructions and providing accurate, context-aware responses for tasks ranging from data analysis to creative writing and specialized problem-solving. This model is powered by Databricks.`,
        },
        isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
        isNew: false,
        isBeta: true,
        isDefault: false,
        isVisible: true,
        isBasic: false,
        isPro: false,
        isPromax: true,
        isEnterprise: true,
        maxInputCharacters: 24000,
    },
    {
        model: 'mistralai/Mixtral-8x7B-Instruct-v0.1',
        endpoint: 'Opensource',
        title: getModelName('mistralai/Mixtral-8x7B-Instruct-v0.1'),
        desciption: {
          title: getModelName('mistralai/Mixtral-8x7B-Instruct-v0.1'),
          token: '32,768',
          content: `Mixtral-8x7B Instruct v0.1 is a versatile LLM that excels in text generation, analysis, and question answering, outperforming many popular models in various applications. This model is powered by Mistral AI.`,
        },
        isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
        isNew: false,
        isBeta: true,
        isDefault: false,
        isVisible: true,
        isBasic: false,
        isPro: false,
        isPromax: true,
        isEnterprise: true,
        maxInputCharacters: 24000,
    },
    {
      model: 'Qwen/Qwen1.5-72B-Chat',
      endpoint: 'Opensource',
      title: getModelName('Qwen/Qwen1.5-72B-Chat'),
      desciption: {
        title: getModelName('Qwen/Qwen1.5-72B-Chat'),
        token: '128,000',
        content: `Qwen 1.5 Chat (72B) transforms your interactions with its state-of-the-art 72 billion parameter AI, delivering engaging, contextually accurate, and fluid conversations.`,
      },
      isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: true,
      isDefault: false,
      isVisible: true,
      isBasic: false,
      isPro: false,
      isPromax: true,
      isEnterprise: true,
      maxInputCharacters: 21000,
    },
    {
      model: 'mistralai/Mixtral-8x22B-Instruct-v0.1',
      endpoint: 'Opensource',
      title: getModelName('mistralai/Mixtral-8x22B-Instruct-v0.1'),
      desciption: {
        title: getModelName('mistralai/Mixtral-8x22B-Instruct-v0.1'),
        token: '65,400',
        content: `Mixtral-8x22B Instruct (141B) is your ultimate ally for precision and expertise, delivering detailed, context-aware guidance with its massive 141 billion parameter architecture.`,
      },
      isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: true,
      isDefault: false,
      isVisible: true,
      isBasic: false,
      isPro: false,
      isPromax: true,
      isEnterprise: true,
      maxInputCharacters: 24000,
    },
  ];

    // Set isVisible based on userPlan conditions
    modelsList.forEach((model) => {
      // console.log("mod:",model);
      if (userPlan.includes('ENTERPRISE') && !model.isEnterprise) {
        model.isVisible = false;
      }
    });

    return modelsList.filter((model) => model.isVisible);
}, [userPlan]);

return models;
}