export default async function countImageToken(image: string): Promise<number> {
  return new Promise<number>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const tilesWidth = Math.ceil(width / 512);
      const tilesHeight = Math.ceil(height / 512);
      const imageToken = 85 + 170 * (tilesWidth * tilesHeight);
      resolve(imageToken);
    };

    img.src = image;
  });
}
