import { usePricingPopup, subscriptionType } from '~/components/helper/store';
import { showPricing } from '~/components/helper/global';
import { HiSparkles } from "react-icons/hi2";

export default function HeaderProButton() {
  const pState = usePricingPopup((state) => state);
  // const sState = subscriptionType((state) => state);
  const userPlan = window.AIPRO_USER?.subscription_type || [];
  const userPlanType = userPlan.includes('PRO') || userPlan.includes('PROMAX') || userPlan.includes('ENTERPRISE');
  
  if (!userPlanType) {
    return (
      <button
        className="max-w-320:w-[80px] max-w-320:px-1 max-w-320:py-2 max-w-320:text-[12px] max-w-320:rounded-md md:w-[140px] animate-peakFromBottom text-md md:text-xl inline-block cursor-pointer rounded-lg bg-gradient-to-b from-yellow-top to-yellow-bottom px-3 py-2 shadow-lg text-white duration-200 ease-in hover:bg-[#F6B818]/80"
        onClick={() => {
          if (window.mixpanel) {
            window.mixpanel.track("Chatpro-App", {
              "click-location": 'Go-Pro-Button',
            });
          }
          showPricing(()=>{
            pState.setShowPricingPopup(true);
          });
        }}
        title="GO PRO"
        type="button"
      >
        GO PRO <HiSparkles className='inline'/>
      </button>
    );
  }

  return null;
}
