import React, { useState, useEffect, useContext } from 'react';
import { addThemeListener, ThemeContext } from '~/hooks/ThemeContext';

import Preview from './Preview.jsx';

export default function Option({ _name = '', data = {} }) {
  const { label = '', className = '', value = '', id = '' } = data;

  // sync to system mode option
  const [class_name, setClassName] = useState(className);

  useEffect(() => {
    const isDark = window.matchMedia('(prefers-color-scheme:dark)').matches;
    const val = isDark ? 'dark-mode' : 'light-mode';
    if (class_name == 'sync-to-system') {
      setClassName(val);
    }

    addThemeListener('(prefers-color-scheme: dark)', (e) => {
      const val = e?.matches ? 'dark-mode' : 'light-mode';
      if (class_name == 'sync-to-system') {
        setClassName(val);
      }
    });
  }, []); // end useEffect for sync to system mode option

  const { theme, setTheme, mode, setMode } = useContext(ThemeContext);
  const changeHandler = (e, _name) => {
    if (_name === 'mode') {
      setMode(e.target.value);
    } else if (_name === 'theme') {
      setTheme(e.target.value);
    }
  };

  return (
    <>
      <div className={'theme-option ' + class_name}>
        <label htmlFor={id} className="option-label">
          <Preview />
          <div className="content">
            <input
              id={id}
              type="radio"
              name={_name}
              value={value}
              className="input-radio"
              onChange={(e) => {
                changeHandler(e, _name);
              }}
              checked={mode === value || theme === value}
            />
            <div className="content-text"> {label} </div>
          </div>
        </label>
      </div>
    </>
  );
}
