export default function BackToDashboard() {
  const dashboardURL = import.meta.env.VITE_DASHBOARD_LINK || 'https://start.ai-pro.org/dashboard';
  const referrer = document.referrer;
  const invalid_referrer = referrer === '' || referrer === window.location.href;

  if (invalid_referrer || window.self !== window.top) {
    return;
  }

  return (
    <a
      href={dashboardURL}
      target="_self"
      className="back-to-dashboard mb-2 flex flex-shrink-0 cursor-pointer items-center gap-3 rounded-md border border-white/20 px-3 py-3 text-sm text-white transition-colors duration-200 hover:bg-gray-500/10"
      aria-label="Back to Dashboard"
    >
      Back to Dashboard
    </a>
  );
}
