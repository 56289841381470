const openai_gpt4_model = import.meta.env.VITE_OPENAI_GPT4_MODEL || 'gpt-4-1106-preview';
const openai_gpt3_model = import.meta.env.VITE_OPENAI_GPT3_MODEL || 'gpt-3.5-turbo-0125';
const claude_model = import.meta.env.VITE_CLAUDE_MODEL || 'claude-3-5-sonnet-20240620';
const dalle_model = import.meta.env.VITE_DALLE_MODEL || 'dall-e-3';

const modelNames = {
  [openai_gpt3_model]: 'GPT-3.5',
  [openai_gpt4_model]: 'GPT-4',
  [claude_model]: 'claude',
  [dalle_model]: 'DALL·E',
  'gpt-4o': 'GPT-4o',
  'gpt-4o-mini': 'GPT-4o mini',
  'gpt-4-pro-max': 'PRO MAX',
  'DALL·E': 'DALL·E 3',
  'claude': 'Claude 3.5 Sonnet',
  'gemini-1.5-flash': 'Gemini 1.5 Flash',
  'gemini-1.5-pro': 'Gemini 1.5 Pro',
  'meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo': 'Meta Llama 3.1 70B Instruct Turbo',
  'meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo': 'Meta Llama 3.1 8B Instruct Turbo',
  'Qwen/Qwen1.5-72B-Chat': 'Qwen 1.5 Chat (72B)',
  'mistralai/Mixtral-8x22B-Instruct-v0.1': 'Mixtral-8x22B Instruct (141B)',
  'google/gemma-2-9b-it': 'Gemma-2 Instruct (9B)',
  'mistralai/Mistral-7B-Instruct-v0.3': 'Mistral (7B) Instruct v0.3',
  'deepseek-ai/deepseek-llm-67b-chat': 'DeepSeek LLM Chat (67B)',
  'databricks/dbrx-instruct': 'DBRX Instruct',
  'mistralai/Mixtral-8x7B-Instruct-v0.1': 'Mixtral-8x7B Instruct v0.1',
};

export function getModelName(model) {
  return modelNames[model] || model;
}
